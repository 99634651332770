"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeaserSliderGeo = void 0;
var GeolocationService_1 = require("./GeolocationService");
var TeaserSliderGeo = /** @class */ (function () {
    function TeaserSliderGeo(teaserSlider, geolocationService) {
        var _this = this;
        this.teaserSlider = teaserSlider;
        this.service = geolocationService;
        this.teaserSlider.getElement().hidden = true;
        this.service.fetch(function (data) {
            var items = _this.teaserSlider.getListElement().querySelectorAll('li');
            var countryCode = GeolocationService_1.availableCountriesCodes.indexOf(data.countryCode.toLowerCase()) == -1
                ? GeolocationService_1.noMatchCountryCode
                : data.countryCode.toLowerCase();
            var visibleItems = 0;
            for (var i = 0; i < items.length; i++) {
                var item = items.item(i);
                var geolocations = item.dataset.geolocations ? item.dataset.geolocations.split(',') : [GeolocationService_1.noMatchCountryCode];
                if (geolocations.indexOf(countryCode) == -1) {
                    item.remove();
                }
                else {
                    visibleItems++;
                }
            }
            if (visibleItems > 0) {
                _this.teaserSlider.getElement().hidden = false;
                _this.teaserSlider.init(0);
            }
        });
    }
    return TeaserSliderGeo;
}());
exports.TeaserSliderGeo = TeaserSliderGeo;

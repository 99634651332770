"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IframeListener = void 0;
var IframeListener = /** @class */ (function () {
    function IframeListener(element) {
        var _this = this;
        this.element = element;
        window.addEventListener("message", function (event) {
            var messArr = event.data.toString().split(',');
            if (event.origin.toLowerCase().indexOf('.playertec.de') < 1) {
                return false;
            }
            ;
            if (messArr.length != 2) {
                return false;
            }
            ;
            if (messArr[0] == 'setHeight') {
                _this.element.style.height = messArr[1];
                return false;
            }
            ;
            if (messArr[0] == 'scrollTop') {
                _this.element.scrollIntoView();
                return false;
            }
            ;
        }, false);
    }
    return IframeListener;
}());
exports.IframeListener = IframeListener;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Typing = void 0;
var Typing = /** @class */ (function () {
    function Typing(typingElement) {
        var _this = this;
        this.writingTag = false;
        this.defaultTypingSpeed = 100;
        this.typingSpeed = this.defaultTypingSpeed;
        this.typingElement = typingElement;
        this.typingElement.dataset.typingData = this.typingElement.innerHTML;
        this.typingElement.innerHTML = "";
        if ('IntersectionObserver' in window
            && 'IntersectionObserverEntry' in window
            && 'intersectionRatio' in window.IntersectionObserverEntry.prototype
            && 'isIntersecting' in window.IntersectionObserverEntry.prototype) {
            var intersectionObserver_1 = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        _this.typingElement = entry.target;
                        intersectionObserver_1.unobserve(_this.typingElement);
                        _this.startTyping();
                    }
                });
            }, { rootMargin: '0px' });
            intersectionObserver_1.observe(this.typingElement);
        }
        else {
            this.typingElement.innerHTML = this.typingElement.dataset.typingData;
        }
    }
    Typing.prototype.startTyping = function () {
        var _this = this;
        setTimeout(function () {
            _this.typeWord(0);
        }, 1000);
    };
    Typing.prototype.typeWord = function (cursorPosition) {
        var _this = this;
        if (cursorPosition < this.typingElement.dataset.typingData.length) {
            var currentLetter = this.typingElement.dataset.typingData[cursorPosition];
            if (this.writingTag === true) {
                this.tag += currentLetter;
            }
            if (currentLetter == "<") {
                this.typingSpeed = 0; //TODO CHANGE TYPING SPEED
                this.tag = "";
                this.writingTag = true;
                this.tag += currentLetter;
            }
            if (!this.writingTag) {
                this.typingElement.innerHTML += currentLetter;
            }
            if (this.writingTag === true && currentLetter === ">") {
                this.typingSpeed = this.defaultTypingSpeed;
                this.writingTag = false;
                this.typingElement.innerHTML += this.tag;
            }
            setTimeout(function () {
                _this.typeWord(cursorPosition + 1);
            }, this.typingSpeed);
        }
        else {
            setTimeout(function () {
                _this.typingElement.classList.add('typing--done');
            }, 3000);
        }
    };
    return Typing;
}());
exports.Typing = Typing;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanoramaImageOverlay = void 0;
var I18n_1 = require("./I18n");
var PanoramaImageOverlay = /** @class */ (function () {
    function PanoramaImageOverlay(pannellum, links, scenes, lang) {
        this.lang = 'de';
        this.isOverlayOpen = false;
        this.pannellum = pannellum;
        this.links = links;
        this.scenes = scenes;
        this.lang = lang;
        this.i18n = new I18n_1.I18n(this.lang);
        this.initDom();
        this.initListeners();
    }
    PanoramaImageOverlay.prototype.initDom = function () {
        if (!document.querySelector('js-pannellum-overlay-style')) {
            var styleElement = document.createElement('style');
            styleElement.appendChild(document.createTextNode("\n                #panorama-overlay-viewer {\n                    width: 100%;\n                    height: 100%;\n                }\n\n                .pnlm-controls-container {\n                    top: 50px;\n                }\n\n                .pnlm-panorama-info {\n                    display: none !important;\n                }\n            "));
            // Setting "pnlm-controls-container" top property because of position of overlay title and fullscreen problems in IE
            document.head.appendChild(styleElement);
        }
    };
    PanoramaImageOverlay.prototype.initListeners = function () {
        var _this = this;
        for (var i = 0; i < this.links.length; i++) {
            this.links[i].addEventListener('click', function (e) {
                e.preventDefault();
                _this.currentScene = e.currentTarget.getAttribute('data-panorama-image-id');
                if (!_this.currentScene)
                    return false;
                _this.togglePanoramaOverlay();
            }, false);
        }
    };
    PanoramaImageOverlay.prototype.togglePanoramaOverlay = function () {
        if (this.isOverlayOpen) {
            this.closeOverlay();
        }
        else {
            this.openOverlay();
        }
    };
    PanoramaImageOverlay.prototype.openOverlay = function () {
        if (!this.overlay) {
            this.renderOverlay();
            this.renderPanorama();
        }
        else {
            this.viewer.loadScene(this.currentScene);
        }
        this.setOverlayTitle(this.scenes[this.currentScene].title);
        this.overlay.style.display = 'block';
        this.isOverlayOpen = true;
    };
    PanoramaImageOverlay.prototype.closeOverlay = function () {
        if (this.overlay)
            this.overlay.style.display = 'none';
        this.isOverlayOpen = false;
        this.currentScene = null;
    };
    PanoramaImageOverlay.prototype.setOverlayTitle = function (title) {
        return document.querySelector('.js-overlay-title').textContent = title.replace(/(<([^>]+)>)/ig, '');
    };
    PanoramaImageOverlay.prototype.renderOverlay = function () {
        var _this = this;
        document.body.insertAdjacentHTML('beforeend', "\n            <div class=\"iframe-overlay iframe-overlay-fullscreen js-panorama-overlay\">\n                <div class=\"iframe-holder\">\n                    <h3><span class=\"js-overlay-title\"></span></h3>\n                    <button class=\"js-close-panorama-overlay close-panorama-overlay\"><span class=\"ic-close\" aria-hidden=\"true\"></span></button>\n                    <div id=\"panorama-overlay-viewer\" class=\"iframe-overlay-frame\"></div>\n                </div>\n            </div>\n        ");
        this.overlay = document.querySelector('.js-panorama-overlay');
        document.querySelector('.js-close-panorama-overlay').addEventListener('click', function () { return _this.closeOverlay(); }, false);
    };
    PanoramaImageOverlay.prototype.renderPanorama = function () {
        var _this = this;
        this.viewer = this.pannellum.viewer('panorama-overlay-viewer', {
            'default': {
                'firstScene': this.currentScene,
                'sceneFadeDuration': 1000
            },
            'autoLoad': true,
            'compass': false,
            'scenes': this.scenes,
            'strings': {
                'loadButtonLabel': this.i18n.getProperty('train-equipment.load', 'Panoramabild laden'),
                'loadingLabel': this.i18n.getProperty('train-equipment.loading', 'Lade ...')
            },
            'hotSpotDebug': false
        });
        this.viewer.on('scenechange', function () {
            _this.currentScene = _this.viewer.getScene();
            _this.setOverlayTitle(_this.scenes[_this.currentScene].title);
        });
    };
    return PanoramaImageOverlay;
}());
exports.PanoramaImageOverlay = PanoramaImageOverlay;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchPageDynamic = void 0;
var Autocomplete_1 = require("./Autocomplete");
var main_1 = require("./main");
var I18n_1 = require("./I18n");
var SearchPageDynamic = /** @class */ (function () {
    function SearchPageDynamic(lang) {
        var _this = this;
        this.lang = lang;
        var self = this;
        this.i18n = new I18n_1.I18n(this.lang);
        this.form = document.getElementById("search-form");
        this.form.addEventListener("submit", function (event) {
            event.preventDefault();
            event.stopPropagation();
            self.doSearch();
        });
        this.searchUrl = this.form.action;
        this.searchTabLinkPanel = document.getElementById("search-tab-link-panel");
        this.searchTabResultPanel = document.getElementById("search-tab-result-panel");
        this.queryInput = new Autocomplete_1.Autocomplete(this.i18n.getProperty("Suche", "Suche"), function (query) {
            if (query.length > 0) {
                var queryParts = query.split(/\s+/);
                var queryString = queryParts.pop();
                if (!queryString.trim())
                    queryString = queryParts.pop();
                (0, main_1.load)(self.autocompleteUrl + "?q=" + queryString + "&lang=" + self.lang, function (xhr) {
                    var jsonResults = JSON.parse(xhr.responseText).suggestions ? JSON.parse(xhr.responseText).suggestions : JSON.parse(xhr.responseText).autocomplete_terms;
                    var jsonTopResults = JSON.parse(xhr.responseText).top_results;
                    // var json = {
                    //     "autocomplete_terms":  [
                    //         "österreich",
                    //         "österreichweit",
                    //         "österreichischen"
                    //     ],
                    //     "top_results": [
                    //         {
                    //             "content": "DE Home Angebote Rundreisen Fernreisen Strandurlaub Kreuzfahrten Bahnreisen Reisen in <strong>Österreich<\u002fstrong> Events & Erlebnisspecials Städtereisen Reisebüros Unsere Standorte 8 Gründe FAQ Fragen und Antworten Suche Fahrplan-Info www.oebb.at Anfragen Daheim ist´s doch am schönsten Reisen in <strong>Österreich<\u002fstrong> < Alle Kategorien Aktuelle <strong>Österreich<\u002fstrong>-Angebote April bis September 2018 Sommerfrische in <strong>Österreich<\u002fstrong> 1 oder 2 Nächtigungen in Kitzbühel, Krems, Kufstein, Mallnitz, Pörtschach, Seefeld oder Zell am See, Inklusive",
                    //             "displayUrl": "https://reisebuero.oebb.at/angebote/reisen-in-oesterreich.html",
                    //             "filetype": "html",
                    //             "score": 4.002457395513007,
                    //             "title": "Reisebüro - Reisen in <strong>Österreich<\u002fstrong>",
                    //             "url": "https://reisebuero.oebb.at/angebote/reisen-in-oesterreich.html"
                    //         }
                    //     ]
                    // };
                    // var jsonResults: any = json.autocomplete_terms;
                    // var jsonTopResults: any = json.top_results;
                    var autoCompleteValues = new Array();
                    for (var i = 0; i < jsonResults.length; i++) {
                        autoCompleteValues.push({ key: jsonResults[i], value: jsonResults[i] });
                    }
                    self.queryInput.showDataWithTopResults(autoCompleteValues, jsonTopResults);
                }, function (xhr) {
                    self.queryInput.showData([]);
                });
            }
        }, document.getElementById("search-query"), 1, false);
        this.queryInput.setI18n(this.i18n);
        this.queryInput.setCloseListenerForPanel();
        self.queryInput.inputElement.addEventListener("change", function (e) {
            if (self.queryInput.isPanelVisible()) {
                e.preventDefault();
                return;
            }
            // self.doSearch();
        });
        this.autocompleteUrl = self.queryInput.inputElement.getAttribute("data-autocomplete-url");
        self.queryInput.inputElement.value = decodeURI(location.hash.replace("#", ""));
        if (location.hash)
            self.doSearch();
        // if (location.hash) {
        //     this.autocompleteUrl = self.queryInput.inputElement.getAttribute("data-autocomplete-url")
        //     self.queryInput.inputElement.value = decodeURI(location.hash.replace("#", ""))
        //     var event: Event
        //     if (document.createEvent) {
        //         // To create a mouse event , first we need to create an event and then initialize it.
        //         event = document.createEvent("Event");
        //         event.initEvent("change", true, true);
        //     }
        //     else {
        //         event = new Event('change', {
        //             'bubbles': true,
        //             'cancelable': true
        //         });
        //     }
        //     self.queryInput.inputElement.dispatchEvent(event);
        // }
        setTimeout(function () {
            _this.queryInput.inputElement.focus();
        }, 100);
    }
    SearchPageDynamic.prototype.doSearch = function () {
        var self = this;
        location.hash = self.queryInput.inputElement.value;
        if (self.queryInput.inputElement.value.length > 0) {
            (0, main_1.load)(self.searchUrl + "?q=" + self.queryInput.inputElement.value + "&lang=" + self.lang, function (xhr) {
                self.queryInput.closeAllPanels();
                var response = JSON.parse(xhr.response);
                // var response = [
                //     {
                //         "label": "reisebuero.oebb.at",
                //         "hits": [
                //             {
                //                 "content": "tasdfasdf asdf asdfawer qasdfa tasdfasdf asdf asdfawer qasdfa tasdfasdf asdf asdfawer qasdfa tasdfasdf asdf asdfawer qasdfa tasdfasdf asdf asdfawer qasdfa ",
                //                 "displayUrl": "http://www.nightjet.com/",
                //                 "filetype": "html",
                //                 "score": 0.0980198754204644,
                //                 "title": "Nightjet - Startseite 1.1",
                //                 "url": "/"
                //             },
                //             {
                //                 "content": "t",         "displayUrl": "http://www.nightjet.com/",
                //                 "filetype": "html",
                //                 "score": 0.0980198754204644,
                //                 "title": "Nightjet - Startseite 1.2",
                //                 "url": "/"
                //             }
                //         ]
                //     },
                //     {
                //         "label": "nightjet.com",
                //         "hits": [
                //             {
                //                 "content": "ttasdfasdf asdf asdfawer qasdfa tasdfasdf asdf asdfawer qasdfa tasdfasdf asdf asdfawer qasdfa tasdfasdf asdf asdfawer qasdfa ",
                //                 "displayUrl": "http://www.nightjet.com/",
                //                 "filetype": "html",
                //                 "score": 0.0980198754204644,
                //                 "title": "Nightjet - Startseite 2.1",
                //                 "url": "/"
                //             }
                //         ]
                //     }
                // ];
                self.searchTabResultPanel.innerHTML = "";
                self.searchTabLinkPanel.innerHTML = "";
                for (var i = 0; i < response.length; i++) {
                    (function () {
                        var holderDiv = document.createElement("div");
                        self.searchTabResultPanel.appendChild(holderDiv);
                        holderDiv.className = "indented-module decrease-padding white";
                        holderDiv.style.display = "none";
                        var currentResultData = response[i].hits;
                        // var link=`<a href="#" class="search-tab-link search-tab-link-active" id="search-tab-link-nightjet">${response[i].label} <span>(${currentResultData.length})</span></a>` as any
                        var link = document.createElement("a");
                        link.classList.add("search-tab-link");
                        if (i == 0) {
                            link.classList.add("search-tab-link-active");
                            holderDiv.style.display = "block";
                        }
                        link.innerHTML = "".concat(response[i].label, " <span>(").concat(currentResultData.length, ")</span>");
                        link.href = "#" + response[i].label;
                        self.searchTabLinkPanel.appendChild(link);
                        link.addEventListener("click", function (event) {
                            event.preventDefault();
                            event.stopPropagation();
                            holderDiv.style.display = "block";
                            var links = self.searchTabLinkPanel.querySelectorAll(".search-tab-link-active");
                            for (var ix = 0; ix < links.length; ix++) {
                                links[ix].classList.remove("search-tab-link-active");
                            }
                            var results = self.searchTabResultPanel.children;
                            for (var ix = 0; ix < results.length; ix++) {
                                results[ix].style.display = "none";
                            }
                            link.classList.add("search-tab-link-active");
                            holderDiv.style.display = "block";
                            var tabbing = function (e) {
                                if (e.keyCode !== 9 || (e.shiftKey && e.keyCode == 9)) {
                                    // link.removeEventListener('keydown', tabbing);
                                    return;
                                }
                                e.preventDefault();
                                var firstResult = holderDiv.querySelector('a');
                                firstResult.focus();
                                firstResult.addEventListener('keydown', function (e) {
                                    if (!(e.shiftKey && e.keyCode == 9))
                                        return;
                                    e.preventDefault();
                                    link.focus();
                                });
                                link.removeEventListener('keydown', tabbing);
                            };
                            link.addEventListener('keydown', tabbing);
                        });
                        var currentResultList = document.createElement("ul");
                        holderDiv.appendChild(currentResultList);
                        for (var ii = 0; ii < currentResultData.length; ii++) {
                            var liElement = document.createElement("li");
                            liElement.classList.add('search-result-item');
                            currentResultList.appendChild(liElement);
                            var aElement = document.createElement("a");
                            liElement.appendChild(aElement);
                            aElement.href = currentResultData[ii].url;
                            aElement.innerHTML = "\n                                <h2 class=\"search-result-item__title\">\n                                    ".concat(currentResultData[ii].title, "\n                                    <span>").concat(currentResultData[ii].url.indexOf(".pdf") > -1 ? "PDF" : self.i18n.getProperty("type.page", "Seite"), "</span>\n                                </h2>\n                                <p>").concat(currentResultData[ii].content, "</p>\n                                <p>").concat(currentResultData[ii].displayUrl, "</p>\n                            ");
                        }
                    })();
                    self.searchTabResultPanel.style.display = "block";
                    //    document.getElementById("search-tab-link-panel").style.display = "block"
                    //   }, null)
                }
                if (response.length > 1) {
                    self.searchTabLinkPanel.style.display = "block";
                }
                else {
                    self.searchTabLinkPanel.style.display = "none";
                }
            }, null);
        }
        this.queryInput.inputElement.focus();
    };
    return SearchPageDynamic;
}());
exports.SearchPageDynamic = SearchPageDynamic;

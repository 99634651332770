"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Observer = void 0;
var Observer = /** @class */ (function () {
    function Observer() {
        this.observers = [];
    }
    Observer.prototype.addObserver = function (observer) {
        this.observers.push(observer);
    };
    Observer.prototype.notifyObservers = function () {
        this.observers.map(function (observer) { return observer.notify(); });
    };
    return Observer;
}());
exports.Observer = Observer;

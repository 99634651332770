"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Co2 = void 0;
var Co2 = /** @class */ (function () {
    function Co2(element) {
        this.element = element;
        if (!element.hasAttribute('data-url')) {
            // Use statistical extrapolation
            this.startExtrapolation();
            return;
        }
        // Use API
        this.startRequest();
    }
    Object.defineProperty(Co2, "CALCULATION_INTERVAL", {
        /** Calculate and render value after ms */
        get: function () { return 5000; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Co2, "COUNT_UP_DURATION", {
        /** Count up value dureation ms */
        get: function () { return 2500; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Co2, "DEFAULT_SAVING_PER_SECOND", {
        /** saving per second in kg */
        get: function () { return 72.93253; },
        enumerable: false,
        configurable: true
    });
    Co2.prototype.startExtrapolation = function () {
        var _this = this;
        var savingTotal = 0;
        var savingNew = 0;
        var savingOld = 0;
        if (this.element.textContent && !isNaN(Number(this.element.textContent.replace(/\s/g, '')))) {
            var savingPerYear = Number(this.element.textContent.replace(/\s/g, ''));
            savingTotal = (savingPerYear / (365 * 24 * (60 * 60))) * 1000; // * 1000 = kg to t
        }
        else {
            var savingPerYear = Co2.DEFAULT_SAVING_PER_SECOND * 365 * 24 * (60 * 60); // Days * Hours * Seconds per Hour
            savingTotal = savingPerYear / (365 * 24 * (60 * 60));
        }
        savingOld = this.calculateStatisticalSaving(savingTotal);
        this.countUp(0, savingOld);
        setInterval(function () {
            savingNew = _this.calculateStatisticalSaving(savingTotal);
            _this.countUp(savingOld, savingNew);
            savingOld = _this.calculateStatisticalSaving(savingTotal);
        }, Co2.CALCULATION_INTERVAL);
    };
    Co2.prototype.calculateStatisticalSaving = function (savingTotal) {
        var date = new Date();
        var timeStart = new Date(date.getFullYear(), 0, 1).setUTCHours(24);
        var timeEnd = date.getTime();
        var timezoneOffset = (-1) * (date.getTimezoneOffset() * 60000);
        var timeSinceStart = Math.floor(((timeEnd - timeStart) + timezoneOffset) / 1000);
        return Number(((timeSinceStart * savingTotal) / 1000).toFixed(2));
    };
    Co2.prototype.startRequest = function () {
        var _this = this;
        // Use API
        this.requestSaving(this.element.dataset.url, function (result) {
            var savingPerSecond = result.savingPerSecond;
            var savingNew = 0;
            var savingOld = 0;
            savingOld = result.cumulatedSaving;
            _this.countUp(0, savingOld / 1000);
            setInterval(function () {
                savingNew = savingOld + (savingPerSecond * (Co2.CALCULATION_INTERVAL / 1000));
                _this.countUp(savingOld / 1000, savingNew / 1000);
                savingOld = savingNew;
            }, Co2.CALCULATION_INTERVAL);
        });
    };
    Co2.prototype.requestSaving = function (url, cb) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                var result = JSON.parse(xhr.response);
                if (cb)
                    cb(result);
            }
            // Local testing, data from "https://obc.rola.at/OBC/Home/CO2Info"
            // if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 0) {
            //     const result = JSON.parse('{"cumulatedSaving":5078604.46,"timestamp":"2020-03-04T09:52:52.881Z","savingPerSecond":0.94}');
            //     if (cb) cb(result);
            // }
        };
        xhr.send(null);
    };
    Co2.prototype.countUp = function (from, to) {
        var _this = this;
        var steps = (to - from) * 100;
        var time = 0;
        var step = Math.ceil(steps / Co2.COUNT_UP_DURATION);
        var _loop_1 = function (i) {
            setTimeout(function () {
                var count = (from + (i / 100));
                _this.render(count, true);
            }, time);
            time = Math.floor(this_1.easeInQuad(i + step, 0, Co2.COUNT_UP_DURATION, steps));
        };
        var this_1 = this;
        for (var i = 0; i < steps; i += step) {
            _loop_1(i);
        }
        setTimeout(function () {
            _this.render(to, true);
        }, Co2.COUNT_UP_DURATION + 10);
    };
    Co2.prototype.render = function (count, decimal) {
        if (decimal === void 0) { decimal = false; }
        var out;
        if (!decimal) {
            out = count.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0, useGrouping: true });
        }
        else {
            out = count.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
        }
        this.element.textContent = out;
    };
    /**
     * Ease In Quad
     *
     * @see {@link http://robertpenner.com/easing/}
     */
    Co2.prototype.easeInQuad = function (t, b, c, d) {
        return c * (t /= d) * t + b;
    };
    return Co2;
}());
exports.Co2 = Co2;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Redirect = void 0;
var Redirect = /** @class */ (function () {
    function Redirect() {
    }
    Redirect.getInstance = function () {
        if (!Redirect.instance) {
            Redirect.instance = new Redirect();
        }
        return Redirect.instance;
    };
    Redirect.prototype.go = function (url, delay) {
        if (delay === void 0) { delay = 5000; }
        if (!url || !delay)
            return;
        console.log('goooooooooo');
        console.log(url, delay);
        window.setTimeout(function () {
            console.log('now');
            window.location.replace(url);
        }, delay);
    };
    return Redirect;
}());
exports.Redirect = Redirect;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KlimaticketScopeMap = exports.AustrianState = void 0;
var AustrianState;
(function (AustrianState) {
    AustrianState["Vienna"] = "vienna";
    AustrianState["LowerAustria"] = "lower-austria";
    AustrianState["Upperaustria"] = "upper-austria";
    AustrianState["Burgenland"] = "burgenland";
    AustrianState["Styria"] = "styria";
    AustrianState["Carinthia"] = "carinthia";
    AustrianState["Salzburg"] = "salzburg";
    AustrianState["Tyrol"] = "tyrol";
    AustrianState["Vorarlberg"] = "vorarlberg";
})(AustrianState = exports.AustrianState || (exports.AustrianState = {}));
var KlimaticketScopeMap = /** @class */ (function () {
    function KlimaticketScopeMap(element) {
        this.isPopupOpen = false;
        this.element = element;
        this.initDom();
        this.initListeners();
    }
    KlimaticketScopeMap.prototype.initDom = function () {
        this.mapContainer = this.element.querySelector('.js-klimaticket-scope-map-container');
        this.links = this.element.querySelectorAll('a');
        this.popupCloseButton = document.createElement('button');
        this.popupCloseButton.innerHTML = "\n\t\t\t<span aria-hidden=\"true\">&times;</span>\n\t\t\t<span class=\"screen-reader-text\">Close</span>\n\t\t";
        this.popupContent = document.createElement('div');
        this.popup = document.createElement('div');
        this.popup.hidden = true;
        this.popup.classList.add('klimaticket-scope-map-popup');
        this.popup.setAttribute('aria-live', 'polite');
        this.popup.appendChild(this.popupCloseButton);
        this.popup.appendChild(this.popupContent);
        this.mapContainer.appendChild(this.popup);
    };
    KlimaticketScopeMap.prototype.initListeners = function () {
        var _this = this;
        this.links.forEach(function (link) {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                var path = e.currentTarget.querySelector('path');
                var linkNode = path.parentNode;
                var state = linkNode
                    .getAttributeNS('http://www.w3.org/1999/xlink', 'href')
                    .substring(1);
                _this.deactivateActivePath();
                _this.currentPath = path;
                _this.currentPath.classList.add('klimaticket-scope-map-active-path');
                _this.openPopup(state);
            });
        });
        this.popupCloseButton.addEventListener('click', function (e) {
            e.preventDefault();
            _this.closePopup();
        });
        this.popup.addEventListener('click', function (e) {
            e.stopPropagation();
        });
        window.addEventListener('click', function (e) {
            _this.closePopup();
        });
    };
    KlimaticketScopeMap.prototype.openPopup = function (state) {
        var _this = this;
        var statePopupContent = this.element.querySelector('#' + state);
        this.popupContent.innerHTML = statePopupContent.innerHTML;
        this.popup.hidden = false;
        // Make screen reader read it for the first time when popup opens
        if (!this.isPopupOpen) {
            setTimeout(function () { return _this.popupContent.innerHTML = statePopupContent.innerHTML; }, 100);
        }
        this.isPopupOpen = true;
    };
    KlimaticketScopeMap.prototype.closePopup = function () {
        this.deactivateActivePath();
        this.popup.hidden = true;
        this.popupContent.innerHTML = '';
        this.isPopupOpen = false;
    };
    KlimaticketScopeMap.prototype.deactivateActivePath = function () {
        if (this.currentPath) {
            this.currentPath.classList.remove('klimaticket-scope-map-active-path');
        }
    };
    return KlimaticketScopeMap;
}());
exports.KlimaticketScopeMap = KlimaticketScopeMap;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.initVideo = exports.Video = void 0;
var Observer_1 = require("./Observer");
var disclaimerUtils = require("./disclaimerUtils");
var Video = /** @class */ (function (_super) {
    __extends(Video, _super);
    function Video(elements, lang) {
        var _this = _super.call(this) || this;
        _this.elements = elements;
        _this.lang = lang;
        _this.createDisclaimer();
        _this.createDisclaimerBtn();
        _this.toggleVideos();
        return _this;
    }
    Video.prototype.deactivateVideos = function () {
        for (var i = 0; i < this.elements.length; i++) {
            var videoElement = this.elements.item(i);
            videoElement.classList.remove('video--activated');
            var frame = videoElement.querySelector('iframe');
            if (frame) {
                videoElement.querySelector('iframe').src = "";
                videoElement.querySelector('iframe').style.pointerEvents = 'none';
            }
            var disclaimerButton = videoElement.querySelector('.js-disclaimer-btn');
            disclaimerUtils.changeDisclaimerBtnText(disclaimerButton, this.lang);
            videoElement.classList.add('video--ready');
        }
    };
    Video.prototype.activateVideos = function () {
        for (var i = 0; i < this.elements.length; i++) {
            var videoElement = this.elements.item(i);
            videoElement.classList.add('video--activated');
            var frame = videoElement.querySelector('iframe');
            if (frame) {
                videoElement.querySelector('iframe').style.pointerEvents = 'unset';
                videoElement.querySelector('iframe').src = videoElement.getAttribute('data-url');
            }
            var disclaimerButton = videoElement.querySelector('.js-disclaimer-btn');
            disclaimerUtils.changeDisclaimerBtnText(disclaimerButton, this.lang);
            videoElement.classList.add('video--ready');
        }
    };
    Video.prototype.toggleVideos = function () {
        if (disclaimerUtils.isYoutubeEnabled()) {
            this.activateVideos();
        }
        else {
            this.deactivateVideos();
        }
    };
    Video.prototype.createDisclaimer = function () {
        for (var i = 0; i < this.elements.length; i++) {
            var videoElement = this.elements.item(i);
            var disclaimer = disclaimerUtils.createDisclaimerTxt(this.lang);
            videoElement.appendChild(disclaimer);
        }
    };
    Video.prototype.createDisclaimerBtn = function () {
        var _this = this;
        for (var i = 0; i < this.elements.length; i++) {
            var videoElement = this.elements.item(i);
            var disclaimerBtn = disclaimerUtils.createDisclaimerBtn();
            videoElement.appendChild(disclaimerBtn);
            disclaimerBtn.addEventListener("click", function (event) {
                disclaimerUtils.toggleYoutubeLocalstorage();
                _this.toggleVideos();
                _this.notifyObservers();
            });
        }
    };
    Video.prototype.notify = function () {
        this.toggleVideos();
    };
    return Video;
}(Observer_1.Observer));
exports.Video = Video;
function initVideo(currentLang) {
    if (document.querySelector('.js-video')) {
        return new Video(document.querySelectorAll('.js-video'), currentLang);
    }
}
exports.initVideo = initVideo;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Quiz = void 0;
var Quiz = /** @class */ (function () {
    function Quiz(element, i18n) {
        this.currentQuestion = 0;
        this.maxQuestions = 0;
        this.selectedAnswers = [];
        this.element = element;
        this.i18n = i18n;
        this.questions = this.element.querySelectorAll('.js-quiz-question');
        this.maxQuestions = this.questions.length;
        this.initDom();
        this.initListeners();
    }
    Object.defineProperty(Quiz, "ANSWER_DELAY", {
        // Ansser delay in ms
        get: function () { return 150; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Quiz, "ANIMATION_DURATION", {
        // Animation duration in ms
        get: function () { return 200; },
        enumerable: false,
        configurable: true
    });
    Quiz.prototype.initDom = function () {
        this.element.style.display = 'block';
        if (this.questions.length) {
            this.questions[this.currentQuestion].style.display = 'block';
            this.questions[this.currentQuestion].classList.add('quiz__question--active');
            this.questions[this.currentQuestion].insertAdjacentHTML('afterbegin', this.renderQuestionCount());
        }
    };
    Quiz.prototype.renderQuestionCount = function () {
        return "\n            <small class=\"quiz-question__count\">\n                ".concat(this.i18n.getProperty('quiz.question', 'Frage'), "\n                ").concat(this.currentQuestion + 1, "\n                ").concat(this.i18n.getProperty('quiz.from', 'von'), "\n                ").concat(this.maxQuestions, "\n            </small>\n        ");
    };
    Quiz.prototype.initListeners = function () {
        var _this = this;
        var _loop_1 = function (i) {
            var inputs = this_1.questions[i].querySelectorAll('input');
            var _loop_2 = function (j) {
                inputs[j].addEventListener('click', function (e) {
                    setTimeout(function () {
                        _this.selectAnswer(inputs[j].value);
                    }, Quiz.ANSWER_DELAY);
                }, false);
            };
            for (var j = 0; j < inputs.length; j++) {
                _loop_2(j);
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.questions.length; i++) {
            _loop_1(i);
        }
    };
    Quiz.prototype.selectAnswer = function (value) {
        this.selectedAnswers.push({
            'id': this.currentQuestion,
            'value': value
        });
        if ((this.maxQuestions - 1) === this.currentQuestion) {
            this.findSolution();
            this.showSolution();
            return;
        }
        this.nextQuestion();
    };
    Quiz.prototype.nextQuestion = function () {
        this.fadeOutElement(this.questions[this.currentQuestion]);
        this.currentQuestion++;
        this.questions[this.currentQuestion].insertAdjacentHTML('afterbegin', this.renderQuestionCount());
        this.fadeInElement(this.questions[this.currentQuestion]);
    };
    Quiz.prototype.findSolution = function () {
        var solutionCounter = {};
        this.selectedAnswers.forEach(function (selectedAnswer) {
            if (!solutionCounter[selectedAnswer.value]) {
                solutionCounter[selectedAnswer.value] = 1;
            }
            else {
                solutionCounter[selectedAnswer.value] = solutionCounter[selectedAnswer.value] + 1;
            }
        });
        var winnerCount = 0;
        var winnerValue;
        var setWinner = function (prop) {
            winnerCount = solutionCounter[prop];
            winnerValue = prop;
        };
        for (var prop in solutionCounter) {
            if (solutionCounter[prop] == winnerCount) {
                // Randomize winner
                if (Math.random() >= 0.5) {
                    setWinner(prop);
                }
            }
            else if (solutionCounter[prop] > winnerCount) {
                setWinner(prop);
            }
        }
        this.winnerValue = winnerValue;
    };
    Quiz.prototype.showSolution = function () {
        var solution = this.element.querySelector("[data-name=\"".concat(this.winnerValue, "\"]"));
        // solution.insertAdjacentHTML('afterbegin', `<small class="quiz-question__count">${this.i18n.getProperty('quiz.result', 'Ergebnis')}</small>`);
        this.fadeOutElement(this.questions[this.currentQuestion]);
        this.fadeInElement(solution);
    };
    Quiz.prototype.fadeOutElement = function (element) {
        element.style.opacity = '1';
        setTimeout(function () {
            element.style.display = 'none';
        }, Quiz.ANIMATION_DURATION);
        element.style.transition = "opacity ".concat(Quiz.ANIMATION_DURATION / 1000, "s ease");
        element.style.opacity = '0';
    };
    Quiz.prototype.fadeInElement = function (element) {
        setTimeout(function () {
            element.style.opacity = '0';
            element.style.display = 'block';
            element.style.transition = "opacity ".concat(Quiz.ANIMATION_DURATION / 1000, "s ease");
            window.getComputedStyle(element).display; // Trigger layout
            element.style.opacity = '1';
        }, Quiz.ANIMATION_DURATION);
    };
    return Quiz;
}());
exports.Quiz = Quiz;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanoramaScenesService = void 0;
var PanoramaScenesService = /** @class */ (function () {
    function PanoramaScenesService(elements) {
        this.data = {};
        this.elements = elements;
        this.collectData();
    }
    PanoramaScenesService.prototype.collectData = function () {
        var _this = this;
        var elements = this.elements;
        var _loop_1 = function (i) {
            if (!elements[i].getAttribute('data-panorama-image-id'))
                return { value: void 0 };
            var id = elements[i].getAttribute('data-panorama-image-id');
            this_1.data[id] = {
                'title': elements[i].getAttribute('data-panorama-image-title') ? elements[i].getAttribute('data-panorama-image-title') : '',
                'type': 'equirectangular',
                'panorama': (function () {
                    var panorama = elements[i].getAttribute('data-panorama-image-url');
                    if ((window.innerWidth <= 640 && elements[i].hasAttribute('data-panorama-image-url-smaller')) || _this.getMaxImageSize() <= 4096) {
                        panorama = elements[i].getAttribute('data-panorama-image-url-smaller');
                    }
                    return panorama;
                })()
            };
            this_1.data[id] = __assign(__assign({}, this_1.data[id]), JSON.parse(elements[i].getAttribute('data-panorama-image-meta')));
        };
        var this_1 = this;
        for (var i = 0; i < elements.length; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    };
    PanoramaScenesService.prototype.getData = function () {
        return this.data;
    };
    PanoramaScenesService.prototype.getMaxImageSize = function () {
        var canvas = document.createElement('canvas');
        var gl = canvas.getContext('experimental-webgl');
        var maxSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
        var extension = gl.getExtension('WEBGL_lose_context');
        if (extension)
            extension.loseContext();
        return maxSize;
    };
    return PanoramaScenesService;
}());
exports.PanoramaScenesService = PanoramaScenesService;

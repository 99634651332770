"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollTo = void 0;
var animated_scroll_to_1 = require("animated-scroll-to");
var ScrollTo = /** @class */ (function () {
    function ScrollTo(link) {
        link.addEventListener('click', function (e) {
            e.preventDefault();
            var hash = link.getAttribute("href");
            var scrollToElement = document.querySelector(hash);
            if (scrollToElement) {
                (0, animated_scroll_to_1.default)(scrollToElement.getBoundingClientRect().top);
            }
        });
    }
    return ScrollTo;
}());
exports.ScrollTo = ScrollTo;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Angebotssuche = void 0;
var Angebotssuche = /** @class */ (function () {
    function Angebotssuche(angebotssuche) {
        this.KEY_GETCATEGORY = "W0.1";
        this.KEY_GETDESTINATIONS_BY_CATEGORY = "W1.3";
        this.KEY_GETOFFERS = "W2.1";
        this.angebotssuche = angebotssuche;
        this.renderWrapper = this.angebotssuche.querySelector('.js-render');
        this.angebotsWizzard = this.angebotssuche.querySelector('.angebotssuche__wizzard');
        this.baseUrl = angebotssuche.getAttribute('data-url');
        this.pageJsonUrl = angebotssuche.getAttribute('data-json-url');
        this.initListeners();
        this.main();
    }
    Angebotssuche.prototype.main = function () {
        var _this = this;
        var queryString = window.location.search;
        if (queryString.indexOf('category') > -1 && queryString.indexOf('ziel') > -1) {
            var zielId = this.getUrlParameter('ziel');
            var category = this.getUrlParameter('category');
            this.getPageData(function () { _this.createAngebote(category, zielId); });
        }
        else if (queryString.indexOf('category') > -1) {
            var category = this.getUrlParameter('category');
            this.createListByCategory(category);
        }
        else {
            this.getPageData(function () { _this.createCategories(); });
        }
        this.updateWizzard();
    };
    Angebotssuche.prototype.updateWizzard = function () {
        var itemCategory = this.angebotsWizzard.querySelector('.angebotssuche__wizzard-item--category');
        var itemZiel = this.angebotsWizzard.querySelector('.angebotssuche__wizzard-item--ziel');
        var itemDetail = this.angebotsWizzard.querySelector('.angebotssuche__wizzard-item--detail');
        var classes = {
            finished: 'angebotssuche__wizzard-item--finished',
            disabled: 'angebotssuche__wizzard-item--disabled'
        };
        var queryString = window.location.search;
        if (queryString.indexOf('category') > -1 && queryString.indexOf('ziel') > -1) {
            itemCategory.classList.add(classes.finished);
            itemZiel.classList.add(classes.finished);
            itemZiel.classList.remove(classes.disabled);
            itemDetail.classList.remove(classes.disabled);
        }
        else if (queryString.indexOf('category') > -1) {
            itemCategory.classList.add(classes.finished);
            itemZiel.classList.remove(classes.finished);
            itemZiel.classList.remove(classes.disabled);
            itemDetail.classList.add(classes.disabled);
        }
        else {
            itemCategory.classList.remove(classes.finished);
            itemZiel.classList.remove(classes.finished);
            itemZiel.classList.add(classes.disabled);
            itemDetail.classList.add(classes.disabled);
        }
    };
    Angebotssuche.prototype.getPageData = function (callback) {
        var _this = this;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', this.pageJsonUrl, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    var result = JSON.parse(xhr.response);
                    _this.dataPages = result.results;
                    _this.dataMagnoliaCategories = result.categoryImages;
                    callback();
                    //this.createCategories();
                }
                else {
                    // TODO 
                }
            }
            else {
                // TODO
            }
        };
        xhr.send();
    };
    Angebotssuche.prototype.createCategories = function () {
        var _this = this;
        this.renderWrapper.innerHTML = "";
        var requestJsonCategories = JSON.stringify({ "Svc": this.KEY_GETCATEGORY });
        var that = this;
        this.postRequest(requestJsonCategories, function (data) {
            _this.dataCategories = data.data ? data.data : [];
            _this.teaserTilesList = document.createElement('ul');
            _this.teaserTilesList.classList.add('teaser-tiles-list');
            _this.teaserTilesList.classList.add('teaser-tiles-list--2');
            for (var i = 0; i < _this.dataCategories.length; i++) {
                var category = _this.dataCategories[i];
                var imageSources = _this.getImagePath(category.KatId);
                var teaserTile = document.createElement('li');
                teaserTile.classList.add('teaser-tile');
                var teaserTileLink = document.createElement('a');
                teaserTileLink.setAttribute('href', '#' + category.KatId);
                teaserTileLink.setAttribute('data-category', category.KatId);
                teaserTileLink.addEventListener('click', function (e) {
                    e.preventDefault();
                    that.addParameter('category', this.getAttribute('data-category'));
                    that.createListByCategory(this.getAttribute('data-category'));
                    that.updateWizzard();
                });
                var teaserTileBox = document.createElement('div');
                teaserTileBox.classList.add('teaser-tile-box');
                var teaserTileImg = document.createElement('img');
                teaserTileImg.setAttribute('src', imageSources.src);
                teaserTileImg.setAttribute('srcset', imageSources.srcset);
                teaserTileImg.setAttribute('alt', category.KatBez);
                var teaserTileTitle = document.createElement('h3');
                teaserTileTitle.classList.add('teaser-tile-box__title');
                teaserTileTitle.textContent = category.KatBez;
                teaserTileBox.appendChild(teaserTileImg);
                teaserTileBox.appendChild(teaserTileTitle);
                teaserTileLink.appendChild(teaserTileBox);
                teaserTile.appendChild(teaserTileLink);
                _this.teaserTilesList.appendChild(teaserTile);
            }
            _this.renderWrapper.appendChild(_this.teaserTilesList);
        });
    };
    ;
    Angebotssuche.prototype.createListByCategory = function (categoryId) {
        var _this = this;
        var requestJson = JSON.stringify([{ "Svc": this.KEY_GETDESTINATIONS_BY_CATEGORY, "KatId": categoryId }]);
        this.postRequest(requestJson, function (data) {
            _this.dataDestinationsByCategory = data.data ? data.data : [];
            _this.renderWrapper.innerHTML = "";
            var listElementsWrapper = document.createElement('div');
            listElementsWrapper.classList.add('angebotssuche-link-list-wrapper');
            for (var i = 0; i < _this.dataDestinationsByCategory.length; i++) {
                var country = _this.dataDestinationsByCategory[i];
                _this.listElements = document.createElement('ul');
                _this.listElements.classList.add('link-list');
                _this.listElements.classList.add('link-list--simple');
                listElementsWrapper.appendChild(_this.listElements);
                var listElement = document.createElement('li');
                listElement.classList.add('link-list__item');
                listElement.classList.add('link-list__item--page');
                listElement.classList.add('link-list__item--highlight');
                listElement.textContent = country.ZielLand;
                _this.listElements.appendChild(listElement);
                for (var z = 0; z < country.Staedte.length; z++) {
                    var city = country.Staedte[z];
                    var listElement = document.createElement('li');
                    listElement.classList.add('link-list__item');
                    listElement.classList.add('link-list__item--page');
                    var elementLink = document.createElement('a');
                    elementLink.setAttribute("href", "#" + city.ZielId);
                    elementLink.textContent = city.ZielOrt.length ? city.ZielOrt : city.ZielLand;
                    elementLink.setAttribute('data-ziel-id', city.ZielId);
                    elementLink.setAttribute('data-category', categoryId);
                    elementLink.addEventListener('click', function (e) {
                        e.preventDefault();
                        var item = e.target;
                        _this.addParameter('ziel', item.getAttribute('data-ziel-id'));
                        _this.createAngebote(item.getAttribute('data-category'), item.getAttribute('data-ziel-id'));
                        _this.updateWizzard();
                    });
                    listElement.appendChild(elementLink);
                    _this.listElements.appendChild(listElement);
                }
            }
            _this.renderWrapper.appendChild(listElementsWrapper);
        });
    };
    Angebotssuche.prototype.createAngebote = function (category, zielId) {
        var _this = this;
        var requestJson = JSON.stringify([{ "Svc": this.KEY_GETOFFERS, "KatId": category, "ZielId": zielId }]);
        this.postRequest(requestJson, function (data) {
            var renderedPages = 0;
            _this.dataZiele = data.data ? data.data : [];
            _this.renderWrapper.innerHTML = "";
            var moduleWrapper = document.createElement('div');
            moduleWrapper.classList.add('indented-module');
            moduleWrapper.classList.add('remove-padding-all');
            for (var i = 0; i < _this.dataZiele.length; i++) {
                var teaserItem = _this.dataZiele[i];
                var detailPageObj = _this.findPageByBC(teaserItem.BC);
                if (detailPageObj) {
                    renderedPages++;
                    var packageTeaser = document.createElement('div');
                    packageTeaser.classList.add('package-teaser');
                    packageTeaser.classList.add('indented-module');
                    packageTeaser.classList.add('remove-padding-left');
                    packageTeaser.classList.add('remove-padding-right');
                    packageTeaser.classList.add('decrease-padding-top');
                    packageTeaser.classList.add('decrease-padding-bottom');
                    var packageTeaserWhite = document.createElement('div');
                    packageTeaserWhite.classList.add('white');
                    var packageTeaserRow = document.createElement('div');
                    packageTeaserRow.classList.add('row');
                    var packageTeaserCol5 = document.createElement('div');
                    packageTeaserCol5.classList.add('col-5');
                    var packageTeaserImg = document.createElement('img');
                    packageTeaserImg.setAttribute('src', detailPageObj.teaserImage.src);
                    packageTeaserImg.setAttribute('srcset', detailPageObj.teaserImage.srcSet);
                    packageTeaserCol5.appendChild(packageTeaserImg);
                    var packageTeaserCol7 = document.createElement('div');
                    packageTeaserCol7.classList.add('col-7');
                    var packageTeaserDestination = document.createElement('h1');
                    packageTeaserDestination.classList.add('package-teaser-item__destination');
                    packageTeaserDestination.textContent = teaserItem.ZielLand + " | " + teaserItem.ZielOrt;
                    var packageTeaserDestinationTitle = document.createElement('h1');
                    packageTeaserDestinationTitle.classList.add('package-teaser-item__heading');
                    packageTeaserDestinationTitle.classList.add('big');
                    packageTeaserDestinationTitle.textContent = teaserItem.ReiseBez;
                    var packageTeaserDestinationText = document.createElement('p');
                    packageTeaserDestinationText.textContent = detailPageObj.uspText;
                    packageTeaserCol7.appendChild(packageTeaserDestination);
                    packageTeaserCol7.appendChild(packageTeaserDestinationTitle);
                    packageTeaserCol7.appendChild(packageTeaserDestinationText);
                    packageTeaserRow.appendChild(packageTeaserCol5);
                    packageTeaserRow.appendChild(packageTeaserCol7);
                    var packageTeaserPricePanel = document.createElement('div');
                    packageTeaserPricePanel.classList.add('row');
                    packageTeaserPricePanel.classList.add('price-panel');
                    var packageTeaserPricePanelCol9 = document.createElement('div');
                    packageTeaserPricePanelCol9.classList.add('col-9');
                    var packageTeaserPricePanelPriceInfo = document.createElement('div');
                    packageTeaserPricePanelPriceInfo.classList.add('price-info');
                    packageTeaserPricePanelPriceInfo.textContent = detailPageObj.priceDescription;
                    var packageTeaserPricePanelPrice = document.createElement('div');
                    packageTeaserPricePanelPrice.classList.add('price');
                    var price = detailPageObj.bookable == "true" ? teaserItem.AbPreis : detailPageObj.price;
                    packageTeaserPricePanelPrice.textContent = price;
                    packageTeaserPricePanelCol9.appendChild(packageTeaserPricePanelPriceInfo);
                    packageTeaserPricePanelCol9.appendChild(packageTeaserPricePanelPrice);
                    var packageTeaserPricePanelCol3 = document.createElement('div');
                    packageTeaserPricePanelCol3.classList.add('col-3');
                    var packageTeaserPricePanelLink = document.createElement('a');
                    packageTeaserPricePanelLink.classList.add('more-btn');
                    packageTeaserPricePanelLink.setAttribute('href', detailPageObj.href);
                    packageTeaserPricePanelLink.setAttribute('title', teaserItem.ReiseBez);
                    packageTeaserPricePanelLink.textContent = "Mehr";
                    packageTeaserPricePanelCol3.appendChild(packageTeaserPricePanelLink);
                    packageTeaserPricePanel.appendChild(packageTeaserPricePanelCol9);
                    packageTeaserPricePanel.appendChild(packageTeaserPricePanelCol3);
                    packageTeaserWhite.appendChild(packageTeaserRow);
                    packageTeaserWhite.appendChild(packageTeaserPricePanel);
                    packageTeaser.appendChild(packageTeaserWhite);
                    moduleWrapper.appendChild(packageTeaser);
                }
            }
            if (renderedPages == 0) {
                var errorMessage = document.createElement('p');
                errorMessage.textContent = 'Leider wurden keine Angebote zu Ihrer Auswahl gefunden.';
                moduleWrapper.appendChild(errorMessage);
            }
            _this.renderWrapper.appendChild(moduleWrapper);
        });
    };
    Angebotssuche.prototype.findPageByBC = function (buchungscode) {
        for (var i = 0; i < this.dataPages.length; i++) {
            if (this.dataPages[i].buchungscode === buchungscode) {
                return this.dataPages[i];
            }
        }
    };
    // Helper Function
    Angebotssuche.prototype.getImagePath = function (catId) {
        var magnoliaObj;
        var imgSrc;
        var imgSrcSet;
        for (var i = 0; i < this.dataMagnoliaCategories.length; i++) {
            if (this.dataMagnoliaCategories[i].imgName.split('.').slice(0, -1).join('.') === catId) {
                magnoliaObj = this.dataMagnoliaCategories[i];
                break;
            }
        }
        if (magnoliaObj) {
            imgSrc = magnoliaObj.imgPath;
            imgSrcSet = magnoliaObj.imageRetina;
        }
        else {
            imgSrc = this.angebotssuche.getAttribute('data-default-category-src');
            imgSrcSet = this.angebotssuche.getAttribute('data-default-category-srcset');
        }
        return {
            src: imgSrc,
            srcset: imgSrcSet
        };
    };
    Angebotssuche.prototype.postRequest = function (jsonParam, callback) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', this.baseUrl + "?data=" + jsonParam, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    var result = JSON.parse(xhr.response);
                    callback(result);
                }
                else {
                    //
                }
            }
            else {
                //
            }
        };
        xhr.send();
    };
    Angebotssuche.prototype.getUrlParameter = function (paramName) {
        paramName = paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)');
        var results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
    ;
    Angebotssuche.prototype.addParameter = function (paramName, paramValue) {
        var re = new RegExp("([?&])" + paramName + "=.*?(&|$)", "i");
        var url = window.location.href;
        var separator = url.indexOf('?') !== -1 ? "&" : "?";
        var newUrl;
        if (url.match(re)) {
            newUrl = url.replace(re, '$1' + paramName + "=" + paramValue + '$2');
        }
        else {
            newUrl = url + separator + paramName + "=" + paramValue;
        }
        window.history.pushState({ paramName: paramValue }, paramValue, newUrl);
    };
    Angebotssuche.prototype.removeParameters = function (parammArray) {
        var url = window.location.href;
        for (var i = 0; i < parammArray.length; i++) {
            var rtn = url.split("?")[0], param, params_arr = [], queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
            if (queryString.length > 0) {
                params_arr = queryString.split("&");
                for (var z = params_arr.length - 1; z >= 0; z -= 1) {
                    param = params_arr[z].split("=")[0];
                    if (param === parammArray[i]) {
                        params_arr.splice(z, 1);
                    }
                }
                rtn = rtn + (params_arr.length > 0 ? "?" + params_arr.join("&") : "");
            }
            url = rtn;
        }
        window.history.pushState(null, null, url);
    };
    Angebotssuche.prototype.initListeners = function () {
        var _this = this;
        window.onpopstate = function (e) {
            _this.main();
        };
        this.angebotsWizzard.querySelector('.angebotssuche__wizzard-item--category a').addEventListener('click', function (e) {
            e.preventDefault();
            _this.removeParameters(['category', 'ziel']);
            _this.main();
        });
        this.angebotsWizzard.querySelector('.angebotssuche__wizzard-item--ziel a').addEventListener('click', function (e) {
            e.preventDefault();
            _this.removeParameters(['ziel']);
            _this.main();
        });
    };
    return Angebotssuche;
}());
exports.Angebotssuche = Angebotssuche;

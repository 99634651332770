"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Waterfall = void 0;
var Waterfall = /** @class */ (function () {
    function Waterfall(sequenzes, localStorageKey) {
        this.sequenzes = sequenzes;
        this.setupEventListener();
        this.observeBackgroundImgAndVideos();
        this.setupOpacity();
        if (localStorageKey) {
            this.localStorageKeySet = localStorage.getItem(localStorageKey) ? true : false;
        }
    }
    Waterfall.prototype.setupEventListener = function () {
        var _this = this;
        window.onscroll = function () {
            _this.setupOpacity();
        };
    };
    Waterfall.prototype.setupOpacity = function () {
        for (var i = 0; i < this.sequenzes.length; i++) {
            var sequenz = this.sequenzes.item(i), sequenzBackground = sequenz.querySelector('.js-waterfall__background'), elementPositions = this.getElementVisibility(sequenz), elementOpacity = 0;
            if (elementPositions.topIsVisible || elementPositions.topIsAboveViewport) {
                var opacity = (1 - (sequenz.getBoundingClientRect().top / window.innerHeight)) * 2;
                elementOpacity = opacity > 1 ? 1 : opacity;
            }
            sequenzBackground.style.opacity = elementOpacity.toString();
        }
    };
    Waterfall.prototype.getElementVisibility = function (elem, offset) {
        if (offset === void 0) { offset = 0; }
        var bounding = elem.getBoundingClientRect();
        var elementPositions = {};
        elementPositions.topIsVisible = (bounding.top - offset) < (window.innerHeight || document.documentElement.clientHeight) && (bounding.top - offset) >= 0;
        elementPositions.topIsUnderViewport = (bounding.top - offset) > (window.innerHeight || document.documentElement.clientHeight);
        elementPositions.topIsAboveViewport = (bounding.top - offset) < 0;
        //elementPositions.bottom = bounding.bottom < (window.innerHeight || document.documentElement.clientHeight) && bounding.bottom >= 0;
        //elementPositions.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
        //elementPositions.any = elementPositions.topIsVisible || elementPositions.left || elementPositions.bottom || elementPositions.right;
        //elementPositions.all = elementPositions.topIsVisible && elementPositions.left && elementPositions.bottom && elementPositions.right;
        return elementPositions;
    };
    ;
    Waterfall.prototype.observeBackgroundImgAndVideos = function () {
        var _this = this;
        if ('IntersectionObserver' in window
            && 'IntersectionObserverEntry' in window
            && 'intersectionRatio' in window.IntersectionObserverEntry.prototype
            && 'isIntersecting' in window.IntersectionObserverEntry.prototype) {
            var intersectionObserver_1 = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        var element = entry.target;
                        intersectionObserver_1.unobserve(element);
                        if (entry.target.dataset.imgUrl) {
                            element.style.backgroundImage = "url(".concat(element.dataset.imgUrl, ")");
                        }
                        else if (entry.target.dataset.videoUrl && _this.localStorageKeySet) {
                            element.querySelector('iframe').src = element.dataset.videoUrl;
                        }
                    }
                });
            }, { rootMargin: '0px' });
            for (var i = 0; i < this.sequenzes.length; i++) {
                var observingBackground = this.sequenzes.item(i).querySelector('.js-waterfall__background--observe');
                if (observingBackground) {
                    intersectionObserver_1.observe(observingBackground);
                }
            }
        }
        else {
            for (var i = 0; i < this.sequenzes.length; i++) {
                var observingBackground = this.sequenzes.item(i).querySelector('.js-waterfall__background--observe');
                if (observingBackground) {
                    if (observingBackground.dataset.imgUrl) {
                        observingBackground.style.backgroundImage = "url(".concat(observingBackground.dataset.imgUrl, ")");
                    }
                    else if (observingBackground.dataset.videoUrl && this.localStorageKeySet) {
                        observingBackground.querySelector('iframe').src = observingBackground.dataset.videoUrl;
                    }
                }
            }
        }
    };
    return Waterfall;
}());
exports.Waterfall = Waterfall;

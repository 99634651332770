"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Autocomplete = void 0;
var main_1 = require("./main");
var I18n_1 = require("./I18n");
var Autocomplete = /** @class */ (function () {
    function Autocomplete(title, dataFunction, inputElement, minimalTextLenght, autocompleteOnFocus, i18n) {
        if (autocompleteOnFocus === void 0) { autocompleteOnFocus = true; }
        var _this = this;
        this.autocompleteOnFocus = false;
        this.data = [];
        this.title = title;
        this.inputElement = inputElement;
        this.dataFunction = dataFunction;
        this.minimalTextLenght = minimalTextLenght;
        this.autocompleteOnFocus = autocompleteOnFocus;
        this.i18n = (i18n) ? i18n : new I18n_1.I18n('de');
        if (!this.minimalTextLenght) {
            this.minimalTextLenght = 0;
        }
        var self = this;
        this.autocompleteId = this.inputElement.id + "-autocomplete";
        if (!document.getElementById(this.autocompleteId)) {
            this.autocomplete = document.createElement("div");
            document.getElementById('main').appendChild(this.autocomplete);
            this.autocomplete.classList.add("autocomplete-panel");
            this.autocomplete.id = this.autocompleteId;
            // this.autocomplete.tabIndex = -1
            this.autocomplete.style.position = "absolute";
            this.autocomplete.style.display = "none";
            this.setARIAPropertyExpandedParent(false);
        }
        else {
            this.autocomplete = document.getElementById(this.autocompleteId);
        }
        setTimeout(function () {
            self.adjustAutocompletePanel();
        }, 300);
        if (this.title && this.title.length > 0) {
            this.autocomplete.innerHTML = "\n            <div>\n                ".concat(this.title, "\n                <div class=\"screen-reader-text js-search-live-results\" aria-live=\"polite\"></div>\n                <button class=\"close-btn js-search-close-btn\" tabindex=\"0\">\n                    <span class=\"screen-reader-text\">").concat(this.i18n.getProperty('search.close', 'Schließen'), "</span>\n                    <span class=\"ic-close ic-default\" aria-hidden=\"true\"></span>\n                </button>\n            </div>\n           ");
        }
        var scrollPanel = document.createElement("div");
        scrollPanel.classList.add("scroll-panel");
        scrollPanel.tabIndex = -1;
        this.autocomplete.appendChild(scrollPanel);
        this.dataList = document.createElement("ul");
        scrollPanel.appendChild(this.dataList);
        this.closeButton = this.autocomplete.querySelector(".close-btn");
        if (this.closeButton) {
            this.closeButton.addEventListener("click", function (event) {
                event.preventDefault();
                event.stopPropagation();
                self.inputElement.focus();
                self.autocomplete.style.display = "none";
                self.setARIAPropertyExpandedParent(false);
            });
            this.closeButton.addEventListener('keydown', function (e) {
                if (e.keyCode === 40) { // Down
                    e.preventDefault();
                    var link = self.autocomplete.querySelector('a');
                    if (link)
                        link.focus();
                }
                else if (e.keyCode === 38 || (e.shiftKey && e.keyCode === 9)) { // Up
                    e.preventDefault();
                    self.inputElement.focus();
                }
            });
        }
        this.inputElement.addEventListener("click", function (event) {
            event.stopPropagation(); // Because of click listener on body
        });
        this.inputElement.addEventListener("focus", function (event) {
            event.preventDefault();
            event.stopPropagation();
            self.adjustAutocompletePanel();
            if (!autocompleteOnFocus)
                return;
            if (self.inputElement.value.length >= self.minimalTextLenght) {
                self.loadData(true);
                self.autocomplete.style.display = "block";
                self.setARIAPropertyExpandedParent(true);
                // self.inputElement.select()
            }
            // self.closeAllPanels();
        });
        this.inputElement.addEventListener("keyup", function (evt) {
            if (evt.keyCode == 9 || evt.keyCode == 13 || evt.keyCode === 40)
                return; // Prevent Panel after Enter on close button or up button
            if (evt.keyCode === 37 || evt.keyCode === 39)
                return; // Prevent Panel on Left/Right
            if (evt.keyCode == 27) {
                self.closeAllPanels();
            }
            else {
                if (self.inputElement.value.length >= self.minimalTextLenght) {
                    self.autocomplete.style.display = "block";
                    self.setARIAPropertyExpandedParent(true);
                    self.loadData();
                }
            }
        });
        this.inputElement.addEventListener('keydown', function (e) {
            if (!_this.isPanelVisible() && e.keyCode === 40) {
                self.autocomplete.style.display = 'block';
                _this.setARIAPropertyExpandedParent(true);
            }
            if (e.keyCode === 37 || e.keyCode === 39)
                return;
            if (!_this.isPanelVisible())
                return;
            if ((e.keyCode === 9 && !(e.shiftKey && e.keyCode === 9)) || e.keyCode === 40) {
                e.preventDefault();
                if (_this.closeButton) {
                    return _this.closeButton.focus();
                }
                else {
                    var link = self.autocomplete.querySelector('a');
                    if (link)
                        link.focus();
                }
            }
        });
        this.setARIAPropertiesInitial();
    }
    Autocomplete.prototype.setARIAPropertiesInitial = function () {
        // this.inputElement.parentElement.setAttribute('role', 'combobox');
        this.inputElement.parentElement.setAttribute('aria-expanded', 'false');
        // this.inputElement.parentElement.setAttribute('aria-owns', this.autocompleteId);
        this.inputElement.parentElement.setAttribute('aria-haspopup', 'listbox');
        this.inputElement.setAttribute('aria-autocomplete', 'list');
        // this.inputElement.setAttribute('aria-controls', this.autocompleteId);
        // this.autocomplete.querySelector('ul').setAttribute('role', 'listbox');
        var label = document.querySelector("[for=\"".concat(this.inputElement.id, "\"]"));
        if (label) {
            if (label.hasAttribute('id')) {
                this.autocomplete.querySelector('ul').setAttribute('aria-labelledby', label.getAttribute('id'));
            }
            else {
                label.setAttribute('id', "".concat(this.inputElement.id, "-label"));
                this.autocomplete.querySelector('ul').setAttribute('aria-labelledby', label.getAttribute('id'));
            }
        }
    };
    Autocomplete.prototype.setARIAPropertiesRow = function (row) {
        row.setAttribute('role', 'option');
        row.setAttribute('aria-selected', 'false');
    };
    Autocomplete.prototype.toggleARIAPropertySelected = function (element) {
        var selected = element.getAttribute('aria-selected');
        if (selected === null || selected === 'false') {
            element.setAttribute('aria-selected', 'true');
        }
        else {
            element.setAttribute('aria-selected', 'false');
        }
    };
    Autocomplete.prototype.setARIAPropertyExpandedParent = function (expanded) {
        var parent = this.inputElement.parentElement;
        parent.setAttribute('aria-expanded', expanded.toString());
    };
    Autocomplete.prototype.disable = function () {
        this.inputElement.disabled = true;
    };
    Autocomplete.prototype.reset = function () {
        this.inputElement.value = "";
        this.selectedKey = "";
        this.selectedValue = "";
    };
    Autocomplete.prototype.enable = function () {
        this.inputElement.disabled = false;
    };
    Autocomplete.prototype.showData = function (data) {
        this.data = data;
        this.dataList.innerHTML = "";
        var currentGroupKey = null;
        if (!data.length && this.i18n) {
            var row_1 = document.createElement('li');
            row_1.classList.add('no-suggestions');
            row_1.textContent = this.i18n.getProperty('search.noSuggestions', 'Keine Vorschläge');
            this.dataList.appendChild(row_1);
            return;
        }
        for (var i = 0; i < data.length; i++) {
            if (data[i].countryCode != currentGroupKey) {
                var self = this;
                var row = document.createElement("li");
                this.dataList.appendChild(row);
                var groupLabel = document.createElement("h4");
                row.appendChild(groupLabel);
                groupLabel.innerHTML = data[i].country;
                currentGroupKey = data[i].countryCode;
            }
            if (data[i].eva) {
                this.addRow({ key: data[i].eva, value: data[i].name, groupKey: data[i].countryCode, groupValue: data[i].country }, i);
            }
            else {
                this.addRow(data[i], i);
            }
        }
        this.renderResultCount(data.length);
    };
    Autocomplete.prototype.getData = function () {
        return this.data;
    };
    Autocomplete.prototype.showDataWithTopResults = function (autocomplete, topResults) {
        var _this = this;
        if (autocomplete.length > 0 && topResults.length > 0) {
            if (!this.i18n)
                this.i18n = new I18n_1.I18n('de');
            var createTitleRow = function (label) {
                var title = document.createElement('h4');
                title.textContent = label;
                var row = document.createElement('li');
                row.appendChild(title);
                return row;
            };
            this.dataList.innerHTML = '';
            this.dataList.appendChild(createTitleRow(this.i18n.getProperty('search.topResults', 'Top-Resultate')));
            topResults.forEach(function (result) {
                var row = document.createElement('li');
                var link = document.createElement('a');
                link.href = result.displayUrl;
                link.innerHTML = "<span>".concat(result.url.indexOf('.pdf') > -1 ? 'PDF' : _this.i18n.getProperty('type.page', 'Seite'), "</span> ") + result.title;
                link.addEventListener('keydown', function (e) {
                    _this.onLinkKeyDown(e, link);
                });
                link.addEventListener('focus', function (e) {
                    _this.onLinkFocus(e, link);
                });
                link.addEventListener('blur', function (e) {
                    _this.onLinkBlur(e, link);
                });
                _this.setARIAPropertiesRow(row);
                row.appendChild(link);
                _this.dataList.appendChild(row);
            });
            this.dataList.appendChild(createTitleRow(this.i18n.getProperty('search.suggestions', 'Suchvorschläge')));
            autocomplete.forEach(function (result, i) {
                _this.addRow(result, i);
            });
            this.renderResultCount(topResults.length + autocomplete.length);
        }
        else {
            this.showData(autocomplete);
        }
    };
    Autocomplete.prototype.renderResultCount = function (count) {
        var _this = this;
        if (this.ariaLivePoliteDebounceTimer)
            clearTimeout(this.ariaLivePoliteDebounceTimer);
        this.ariaLivePoliteDebounceTimer = setTimeout(function () {
            // Wait, to not read it too often
            if (count == 1)
                return _this.autocomplete.querySelector('.js-search-live-results').textContent = count + ' ' + _this.i18n.getProperty('search.suggestion', 'Suchvorschlag');
            _this.autocomplete.querySelector('.js-search-live-results').textContent = count + ' ' + _this.i18n.getProperty('search.suggestions', 'Suchvorschläge');
        }, 500);
    };
    Autocomplete.prototype.loadData = function (ignoreInputValue) {
        if (ignoreInputValue === void 0) { ignoreInputValue = false; }
        this.dataFunction(ignoreInputValue ? "" : this.inputElement.value);
    };
    Autocomplete.prototype.addRow = function (data, index) {
        var _this = this;
        var row = document.createElement("li");
        var link = document.createElement("a");
        link.href = "#" + data.value;
        link.innerHTML = data.value;
        this.setARIAPropertiesRow(row);
        row.appendChild(link);
        this.dataList.appendChild(row);
        link.addEventListener("click", function (evt) {
            _this.onLinkClick(evt, data);
        });
        link.addEventListener("keydown", function (evt) {
            _this.onLinkKeyDown(evt, link);
        });
        link.addEventListener('focus', function (e) {
            _this.onLinkFocus(e, link);
        });
        link.addEventListener('blur', function (e) {
            _this.onLinkBlur(e, link);
        });
    };
    Autocomplete.prototype.onLinkClick = function (evt, data) {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.autocompleteOnFocus) {
            this.inputElement.value = data.value;
            this.selectedKey = data.key;
            this.selectedValue = data.value;
        }
        else {
            var valueParts = this.inputElement.value.split(/\s+/);
            valueParts.pop();
            valueParts.push(data.value);
            this.inputElement.value = valueParts.join(" ");
            this.selectedKey = data.key;
            this.selectedValue = valueParts.join(" ");
        }
        var event;
        if (document.createEvent) {
            event = document.createEvent('Event');
            event.initEvent('change', true, true);
        }
        else {
            event = new Event('change', { 'bubbles': true, 'cancelable': true });
        }
        this.inputElement.dispatchEvent(event);
        this.inputElement.focus();
        this.closeAllPanels();
    };
    Autocomplete.prototype.onLinkKeyDown = function (evt, link) {
        if (evt.keyCode == 40 || (evt.keyCode === 9 && !(evt.shiftKey && evt.keyCode === 9))) { // Down
            evt.preventDefault();
            evt.stopPropagation();
            var currentParent = link.parentElement.nextElementSibling;
            if (currentParent) {
                var nextLink = currentParent.childNodes.item(0);
                if (nextLink && nextLink.tagName.toLowerCase() == "a") {
                    nextLink.focus();
                }
                else {
                    var nextParent = currentParent.nextElementSibling;
                    if (nextParent) {
                        var nextLink2 = nextParent.childNodes.item(0);
                        if (nextLink2 && nextLink2.tagName.toLowerCase() == "a") {
                            nextLink2.focus();
                        }
                    }
                    else {
                        // return; // Stop at last element
                        this.inputElement.focus();
                        this.closeAllPanels();
                    }
                }
            }
            else {
                // return; // Stop at last element
                this.inputElement.focus();
                this.closeAllPanels();
            }
        }
        else if (evt.keyCode == 38 || (evt.shiftKey && evt.keyCode === 9)) { // Up
            evt.preventDefault();
            evt.stopPropagation();
            var currentParent = link.parentElement.previousElementSibling;
            if (currentParent) {
                var nextLink = currentParent.childNodes.item(0);
                if (nextLink && nextLink.tagName.toLowerCase() == "a") {
                    nextLink.focus();
                }
                else {
                    var nextParent = currentParent.previousElementSibling;
                    if (nextParent) {
                        var nextLink2 = nextParent.childNodes.item(0);
                        if (nextLink2 && nextLink2.tagName.toLowerCase() == "a") {
                            nextLink2.focus();
                        }
                        else { // first
                            if (this.closeButton) {
                                this.closeButton.focus();
                            }
                            else {
                                this.inputElement.focus();
                            }
                        }
                    }
                    else { // first
                        if (this.closeButton) {
                            this.closeButton.focus();
                        }
                        else {
                            this.inputElement.focus();
                        }
                    }
                }
            }
            else {
                if (document.querySelector('.js-search-close-btn')) {
                    return document.querySelector('.js-search-close-btn').focus();
                }
                else {
                    return this.inputElement.focus();
                }
            }
        }
        else if (evt.keyCode == 27) {
            this.closeAllPanels();
            this.inputElement.focus();
        }
        else if (evt.keyCode == 9) {
            var isLastElement = Boolean(!link.parentElement.nextElementSibling);
            if (isLastElement) {
                this.inputElement.focus();
            }
        }
    };
    Autocomplete.prototype.onLinkFocus = function (e, link) {
        this.toggleARIAPropertySelected(link.parentElement);
    };
    Autocomplete.prototype.onLinkBlur = function (e, link) {
        this.toggleARIAPropertySelected(link.parentElement);
    };
    Autocomplete.prototype.isPanelVisible = function () {
        var panel = document.querySelector("#".concat(this.inputElement.id, "-autocomplete"));
        if (panel && panel.style.display !== 'none') {
            return true;
        }
        return false;
    };
    Autocomplete.prototype.enableAutocompleteOnFocus = function () {
        this.autocompleteOnFocus = true;
    };
    Autocomplete.prototype.disableAutocompleteOnFocus = function () {
        this.autocompleteOnFocus = false;
    };
    Autocomplete.prototype.closeAllPanels = function () {
        var autocompletPanels = document.querySelectorAll(".autocomplete-panel");
        for (var i = 0; i < autocompletPanels.length; i++) {
            autocompletPanels[i].querySelector('.scroll-panel').scrollTop = 0;
            autocompletPanels[i].style.display = "none";
        }
        var calendarPanels = document.querySelectorAll(".calendar-panel");
        for (var i = 0; i < calendarPanels.length; i++) {
            calendarPanels[i].style.display = "none";
        }
        this.setARIAPropertyExpandedParent(false);
    };
    Autocomplete.prototype.setI18n = function (i18n) {
        this.i18n = i18n;
    };
    Autocomplete.prototype.setCloseListenerForPanel = function (panelSelector) {
        var _this = this;
        document.body.addEventListener('click', function (e) {
            if (!panelSelector) {
                setTimeout(function () {
                    _this.closeAllPanels();
                }, 10);
            }
            else {
                setTimeout(function () {
                    document.querySelector(panelSelector).querySelector('.scroll-panel').scrollTop = 0;
                    document.querySelector(panelSelector).style.display = "none";
                    _this.setARIAPropertyExpandedParent(false);
                }, 10);
            }
        });
    };
    Autocomplete.prototype.removeCloseListener = function () {
        this.inputElement.removeEventListener('blur', function () { });
    };
    Autocomplete.prototype.adjustAutocompletePanel = function () {
        var clientRect = this.inputElement.getBoundingClientRect();
        var autocompleteSpace = (document.querySelector('body').clientWidth <= 640) ? 8 : 20;
        this.autocomplete.style.top = (clientRect.top + (0, main_1.default)() + clientRect.height + autocompleteSpace) + "px";
        this.autocomplete.style.left = (clientRect.left) + "px";
    };
    return Autocomplete;
}());
exports.Autocomplete = Autocomplete;

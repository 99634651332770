"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisibilityObserver = void 0;
/**
 * Abstract class to handle visibility change of page for objects.
 *
 * @see {@link https://developer.mozilla.org/de/docs/Web/API/Page_Visibility_API|MDN}
 */
var VisibilityObserver = /** @class */ (function () {
    function VisibilityObserver() {
        var _this = this;
        if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
            this.hidden = 'hidden';
            this.visibilityChange = 'visibilitychange';
        }
        else if (typeof document.msHidden !== 'undefined') {
            this.hidden = 'msHidden';
            this.visibilityChange = 'msvisibilitychange';
        }
        else if (typeof document.webkitHidden !== 'undefined') {
            this.hidden = 'webkitHidden';
            this.visibilityChange = 'webkitvisibilitychange';
        }
        if (typeof document[this.hidden] !== 'undefined') {
            document.addEventListener(this.visibilityChange, function () {
                _this.handleVisibilityChange();
            }, false);
        }
        else {
            console.log('Browser does not support Page Visibility API.');
        }
    }
    VisibilityObserver.prototype.handleVisibilityChange = function () {
        if (document[this.hidden]) {
            this.handleHiddenState();
        }
        else {
            this.handleVisibleState();
        }
    };
    return VisibilityObserver;
}());
exports.VisibilityObserver = VisibilityObserver;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.I18n = void 0;
var I18n = /** @class */ (function () {
    function I18n(lang) {
        this.lang = lang;
        this.objects = [
            { key: "type.page", de: "Seite", en: "Page", it: "Pagina", nl: "Pagina", fr: "Page" },
            { key: "global.yes", de: "Ja", en: "Yes", it: "Si", nl: "Ja", fr: "Oui" },
            { key: "global.no", de: "Nein", en: "No", it: "No", nl: "Nee", fr: "Non" },
            { key: "ticketshop.language", de: "de", en: "en", it: "it", nl: "en", fr: "en" },
            { key: "global.externalLink", de: "Öffnet im neuen Fenster", en: "Opens a new window", it: "Apre una nuova finestra", nl: "Opent een nieuw venster", fr: "Ouvre une nouvelle fenêtre" },
            { key: "global.navigation.expand", de: "Untermenü von", en: "Open from", it: "Sottomenu di", nl: "Submenu van", fr: "Sous-menu de" },
            { key: "global.navigation.subMenuClose", de: "Untermenü schließen", en: "Close Submenu", it: "Chiudi sottomenu", nl: "Submenu sluiten", fr: "Fermer le sous-menu" },
            { key: "global.moreAboutThis", de: "Mehr dazu", en: "More about this", it: "Ulteriori informazioni al riguardo", nl: "Meer informatie", fr: "En savoir plus" },
            { key: "global.map.scroll", de: "Strg + Scrollen zum Zoomen der Karte", en: "Use ctrl + scroll to zoom the map", it: "Utilizza CTRL + scorrimento per eseguire lo zoom della mappa", nl: "Ctrl + scrollen om in te zoomen op de kaart", fr: "Ctrl + défiler pour zoomer sur la carte" },
            { key: "dialog.cancel", de: "Abbrechen", en: "Cancel", it: "Annulla", nl: "Annuleren", fr: "Arrêter" },
            { key: "dialog.continue", de: "Weiter", en: "Continue", it: "Avanti", nl: "Verder", fr: "Continue" },
            { key: "dialog.title", de: "Ticket buchen", en: "Book ticket", it: "Acquista un biglietto", nl: "Kaartje boeken", fr: "Réserver un ticket" },
            { key: "dialog.text", de: "Sie verlassen jetzt die ÖBB Nightjet Website und werden in den ÖBB Ticketshop weitergeleitet.", en: "You are leaving the ÖBB Nightjet website and are redirected to the ÖBB Ticket Shop.", it: "Ora uscirete dal sito web ÖBB Nightjet e sarete reindirizzati al ticket shop ÖBB.", nl: "U verlaat nu de ÖBB Nightjet website en u wordt doorgestuurd naar de ÖBB ticketshop.", fr: "Vous allez quitter ÖBB Nightjet et être redirigé vers la boutique de ticket ÖBB." },
            { key: "dialog.person_ticket", de: "Personentickets", en: "Passenger tickets", it: "Biglietti passeggeri", nl: "Kaartje voor personenvervoer", fr: "Tickets pour personnes" },
            { key: "dialog.car_ticket", de: "Personentickets und Auto/Motorradtransport", en: "Passenger tickets and car/motorcycle transport", it: "Biglietti passeggeri e trasporto auto/motocicletta", nl: "Kaartje voor personenvervoer en auto-/motortransport", fr: "Tickets pour personnes et transport auto/moto" },
            { key: "search.term", de: "Suchbegriff", en: "Search text", it: "Termine di ricerca", nl: "Zoekterm", fr: "Terme de recherche" },
            { key: "search.topResults", de: "Beste Ergebnisse", en: "Top Results", it: "I migliori risultati", nl: "Beste resultaten", fr: "Meilleurs résultats" },
            { key: "search.suggestion", de: "Suchvorschlag", en: "Search Suggestion", it: "Suggestion de recherche ", nl: "Zoeksuggestie", fr: "Suggestion de recherche" },
            { key: "search.suggestions", de: "Suchvorschläge", en: "Search Suggestions", it: "Suggerimenti di ricerca", nl: "Zoeksuggesties", fr: "Propositions de recherche" },
            { key: "search.noSuggestions", de: "Keine Vorschläge", en: "No suggestions", it: "Nessun suggerimento", nl: "Geen suggesties", fr: "Aucune proposition" },
            { key: "search.close", de: "Schließen", en: "Close", it: "Vicina", nl: "Sluiten", fr: "Fermer" },
            { key: "search.result", de: "Suchergebnis", en: "Search result", it: "Risultati della ricerca", nl: "Zoekresultaat", fr: "Résultat de recherche" },
            { key: "search.results", de: "Suchergebnisse", en: "Search results", it: "Risultati della ricerca", nl: "Zoekresultaten", fr: "Résultats de recherche" },
            { key: "imageTitleSlider.play", de: "Start", en: "Play", it: "Suonare", nl: "Start", fr: "Start" },
            { key: "imageTitleSlider.pause", de: "Stop", en: "Pause", it: "Pausa", nl: "Stop", fr: "Stop" },
            { key: "connections.country.autocomplete", de: "Land auswählen", en: "Choose country", it: "Scegli il paese", nl: "Land selecteren", fr: "Sélectionner le pays" },
            { key: "connections.cartransport.possible", de: "Fahrzeugtransport möglich", en: "Vehicle transport possible", it: "Il trasporto dei veicoli è possibile", nl: "Voertuigtransport mogelijk", fr: "Transport de véhicule possible" },
            { key: "connections.cartransport.notpossible", de: "Fahrzeugtransport nicht möglich", en: "Vehicle transport not possible", it: "Il trasporto dei veicoli non è possibile", nl: "Voertuigtransport niet mogelijk", fr: "Transport de véhicule impossible" },
            { key: "connections.book.button", de: "Ticket buchen", en: "Book ticket", it: "Acquista un biglietto", nl: "Kaartje boeken", fr: "Réserver un ticket" },
            { key: "connections.partner", de: "Verbindungen von Partnerbahnen", en: "Connections of Nightjet partners", it: "Collegamenti delle ferrovie partner", nl: "Aansluitingen van partner-spoorwegmaatschappijen", fr: "Connexions de partenaires" },
            { key: "connection.price.from", de: "ab", en: "from", it: "da", nl: "vanaf ", fr: "à partir de " },
            { key: "connection.partner.link", de: "/ausstattung/nightjet-partner.html", en: "/en/ausstattung/nightjet-partner.html", it: "/it/ausstattung/nightjet-partner.html", nl: "/nl/ausstattung/nightjet-partner.html", fr: "/fr/ausstattung/nightjet-partner.html" },
            { key: "connection.seatcar", de: "Sitzwagen", en: "Seating carriage", it: "Carro di seduta", nl: "Rijtuig met zitaccommodatie", fr: "Voiture place assise" },
            { key: "connection.couchette", de: "Liegewagen", en: "Couchette", it: "Couchette", nl: "Ligrijtuig", fr: "Voiture couchette" },
            { key: "connection.sleeper", de: "Schlafwagen", en: "Sleeper cabin", it: "Scompartimento", nl: "Wagon-lit", fr: "Wagon-lit" },
            { key: "connection.seatcar.text", de: "Sitzwagenabteil 2. Klasse mit 6 Plätzen", en: "Seating carriage 2nd class with 6 seats", it: "Carro di seduta 2° classe con 6 posti", nl: "Rijtuigafdeling met zitaccommodatie 2e klasse met 6 plaatsen", fr: "Voiture place assise 2ème classe 6 places" },
            { key: "connection.seatcar.title", de: "Sitzwagenabteil 2. Klasse mit 6 Plätzen", en: "Seating carriage 2nd class with 6 seats", it: "Carro di seduta 2° classe con 6 posti", nl: "Rijtuigafdeling met zitaccommodatie 2e klasse met 6 plaatsen", fr: "Voiture place assise 2ème classe 6 places" },
            { key: "connection.couchette6.title", de: "Liegewagenabteil mit 6 Liegen", en: "Couchette with 6 berths", it: "Couchette con 6 ancoraggi", nl: "Ligrijtuigafdeling met 6 ligbedden", fr: "Voiture couchette 6 couchettes" },
            { key: "connection.couchette6.text", de: "Liegewagenabteil mit 6 Liegen", en: "Couchette with 6 berths", it: "Couchette con 6 ancoraggi", nl: "Ligrijtuigafdeling met 6 ligbedden", fr: "Voiture couchette 6 couchettes" },
            { key: "connection.couchette4.title", de: "Liegewagenabteil mit 4 Liegen", en: "Couchette with 4 berths", it: "Couchette con 4 ancoraggi", nl: "Ligrijtuigafdeling met 4 ligbedden", fr: "Voiture couchette 4 couchettes" },
            { key: "connection.couchette4.text", de: "Liegewagenabteil mit 4 Liegen", en: "Couchette with 4 berths", it: "Couchette con 4 ancoraggi", nl: "Ligrijtuigafdeling met 4 ligbedden", fr: "Voiture couchette 4 couchettes" },
            { key: "connection.sleeper3.title", de: "Schlafwagenabteil mit 3 Betten", en: "Sleeper cabin Triple", it: "Scompartimento Triple", nl: "Slaaprijtuigafdeling met 3 bedden", fr: "Wagon-lit 3 lits" },
            { key: "connection.sleeper3.text", de: "Schlafwagenabteil mit 3 Betten", en: "Sleeper cabin Triple", it: "Scompartimento Triple", nl: "Slaaprijtuigafdeling met 3 bedden", fr: "Wagon-lit 3 lits" },
            { key: "connection.sleeper2.title", de: "Schlafwagenabteil mit 2 Betten", en: "Sleeper cabin Double", it: "Scompartimento Double", nl: "Slaaprijtuigafdeling met 2 bedden", fr: "Wagon-lit 2 lits" },
            { key: "connection.sleeper2.text", de: "Schlafwagenabteil mit 2 Betten", en: "Sleeper cabin Double", it: "Scompartimento Double", nl: "Slaaprijtuigafdeling met 2 bedden", fr: "Wagon-lit 2 lits" },
            { key: "connection.sleeper1.title", de: "Schlafwagenabteil mit 1 Bett ", en: "Sleeper cabin Single", it: "Scompartimento Single", nl: "Slaaprijtuigafdeling met 1 bed", fr: "Wagon-lit 1 lit" },
            { key: "connection.sleeper1.text", de: "Schlafwagenabteil mit 1 Bett ", en: "Sleeper cabin Single", it: "Scompartimento Single", nl: "Slaaprijtuigafdeling met 1 bed", fr: "Wagon-lit 1 lit" },
            { key: "connections.station.autocomplete", de: "Bahnhof auswählen", en: "Choose station", it: "Seleziona stazione", nl: "Station selecteren", fr: "Sélectionner une gare" },
            { key: "connections.station.from.autocomplete.title", de: "Abfahrtsbahnhof auswählen", en: "Choose departure station", it: "Scegli la stazione di partenza", nl: "Vertrekstation selecteren", fr: "Sélectionner la gare de départ" },
            { key: "connections.station.to.autocomplete.title", de: "Zielbahnhof auswählen", en: "Choose arrival station", it: "Selezionare la stazione di destinazione", nl: "Bestemmingsstation selecteren", fr: "Sélectionner la gare d'arrivée" },
            { key: "connections.onefound", de: "1 Verbindung gefunden", en: "1 connection found", it: "1 connessione trovata", nl: "1 aansluiting gevonden", fr: "1 correspondance trouvée" },
            { key: "connections.morefound", de: "Verbindungen gefunden", en: "connections found", it: "Composti trovati", nl: "Aansluitingen gevonden", fr: "Correspondances trouvées" },
            { key: "connections.earlier.connections", de: "frühere Verbindungen", en: "earlier connections", it: "connessioni precedenti", nl: "eerdere aansluitingen", fr: "Correspondances précédentes" },
            { key: "connections.later.connections", de: "spätere Verbindungen", en: "later connections", it: "connessioni successive", nl: "latere aansluitingen", fr: "Correspondances suivantes" },
            { key: "connections.nocombinedofferfound", de: "Leider nicht verfügbar", en: "Unfortunately not available", it: "Siamo spiacenti, non disponibile", nl: "Helaas niet beschikbaar", fr: "Malheureusement indisponible" },
            { key: "connections.noofferfound", de: "Keine Angebote verfügbar", en: "No offers available", it: "Nessuna offerta disponibile", nl: "Geen aanbiedingen beschikbaar", fr: "Aucune offre disponible" },
            { key: "connection.biketransport", de: "Fahrradmitnahme möglich", en: "Bicycle transport is possible", it: "Possibilità di trasporto biciclette", nl: "Fietstransport mogelijk", fr: "Possibilité d'emmener le vélo" },
            { key: "connection.wheelchairtransport", de: "Rollstuhlabteil vorhanden", en: "Wheelchair compartment available", it: "Scomparto sedia a rotelle disponibile", nl: "Rolstoelafdeling aanwezig", fr: "Section chaise roulante" },
            { key: "connections.book.button.cartrain.url", de: "https://shop.oebbtickets.at/de/shop/folder/5ba1d1cd121f76deee526d386b235638", en: "https://shop.oebbtickets.at/en/shop/folder/5ba1d1cd121f76deee526d386b235638", it: "https://shop.oebbtickets.at/en/shop/folder/5ba1d1cd121f76deee526d386b235638", nl: "XXX", fr: "XXX" },
            { key: "connections.price.from", de: "ab", en: "from", it: "da", nl: "vanaf", fr: "à partir de" },
            { key: "connections.date.today", de: "Heute", en: "Today", it: "Oggi", nl: "vandaag", fr: "Aujourd'hui" },
            { key: "connections.date.tomorrow", de: "Morgen", en: "Tomorrow", it: "Domani", nl: "morgen", fr: "Demain" },
            { key: "panorama.title", de: "360 Grad Bild", en: "360 degree picture", it: "Foto a 360 gradi", nl: "360 graden afbeelding", fr: "Image 360 degrés" },
            { key: "train-equipment.load", de: "Panoramabild laden", en: "Load panoramic picture", it: "Carica un'immagine panoramica", nl: "Panoramabeeld laden", fr: "Charger une image panorama" },
            { key: "train-equipment.loading", de: "Laden ...", en: "Loading ...", it: "Caricamento ...", nl: "Laden...", fr: "Chargement ..." },
            {
                key: "connection.infotext",
                de: "Bei EuroNight-Z\u00FCgen k\u00F6nnen Serviceumfang und Wagenmaterial vom dargestellten \u00D6BB Nightjet-Angebot abweichen.",
                en: "On EuroNight trains, the scope of services and rolling stock may differ from the \u00D6BB Nightjet offer shown.",
                it: "Sui treni EuroNight, l'ambito dei servizi e del materiale rotabile pu\u00F2 differire dall'offerta \u00D6BB Nightjet mostrata.",
                nl: "Op EuroNight-treinen kan de omvang van de diensten en het rollend materieel afwijken van het getoonde \u00D6BB Nightjet-aanbod.",
                fr: "Sur les trains EuroNight, l'\u00E9tendue des services et du mat\u00E9riel roulant peut diff\u00E9rer de l'offre \u00D6BB Nightjet pr\u00E9sent\u00E9e."
            },
            {
                key: "connection.info.buildingsite",
                de: "Durch Baustellen kann es zu Abweichungen vom regul\u00E4ren Fahrplan kommen - bitte beachten Sie daher die aktuellen Fahrzeiten und Halte unter <a href=\"https://fahrplan.oebb.at\" target=\"_blank\">fahrplan.oebb.at</a>.",
                en: "Due to construction sites, deviations from the regular timetable may occur - please pay attention to the current timetables and stops at <a href=\"https://fahrplan.oebb.at\" target=\"_blank\">fahrplan.oebb.at</a>.",
                it: "A causa di lavori, si possono verificare delle deviazioni dal percorso regolare - si prega di prestare attenzione agli orari e alle fermate attuali su <a href=\"https://fahrplan.oebb.at\" target=\"_blank\">fahrplan.oebb.at</a>.",
                nl: "Vanwege bouwplaatsen kunnen afwijkingen van de dienstregeling ontstaan - let daarom op de huidige vertrektijden en stations op <a href=\"https://fahrplan.oebb.at",
                fr: "Des chantiers peuvent entra\u00EEner des modifications de l'horaire r\u00E9gulier - veuillez regarder les horaires actuels et haltes actuelles sur <a href=\"https://fahrplan.oebb.at"
            },
            {
                key: 'connections.route.usual',
                de: 'Gewöhnlicher Zuglauf, kann vom aktuellen Zuglauf abweichen.',
                en: 'Usual train route, may differ from the current train route.',
                it: 'Il percorso del treno ordinario può differire dall\'attuale percorso del treno.',
                nl: 'Gewone treinroute, kan afwijken van de huidige treinroute.',
                fr: 'Itinéraire de train ordinaire, peut différer de l\'itinéraire de train actuel.',
            },
            {
                key: 'connections.route.loading',
                de: 'Zuglauf wird geladen ...',
                en: 'Train route is loading ...',
                it: 'Scaricando il percorso del treno ...',
                nl: 'Treinroute wordt geladen ...',
                fr: 'L\'itinéraire du train est en cours de chargement ...',
            },
            {
                key: 'connections.route.notFound',
                de: 'Für den ausgewählten Tag konnte keine direkte Nachtzugverbindung gefunden werden.',
                en: 'No direct night train connection could be found for the selected day.',
                it: 'Non è stato trovato alcun collegamento ferroviario notturno diretto per il giorno selezionato.',
                nl: 'Voor de geselecteerde dag kon geen directe nachttreinverbinding worden gevonden.',
                fr: 'Aucune liaison ferroviaire de nuit directe n\'a pu être trouvée pour le jour sélectionné.',
            },
            {
                key: 'connections.route.unavailable',
                de: 'Bitte versuchen Sie es später erneut.',
                en: 'Please try again later.',
                it: 'Per favore riprova più tardi.',
                nl: 'Probeer het later nog eens.',
                fr: 'Veuillez réessayer plus tard.',
            },
            {
                key: 'connections.route.deviation',
                de: 'Für den gewählten Tag gibt es keine Verbindungen. Die nächsten Verbindungen sind:',
                en: 'There are no connections for the selected day. The next connections are:',
                it: 'Non ci sono collegamenti per il giorno selezionato. I prossimi collegamenti sono:',
                nl: 'Er zijn geen verbindingen voor de geselecteerde dag. De volgende verbindingen zijn:',
                fr: ' Il n\'y a aucune connexion pour le jour sélectionné. Les prochaines connexions sont:',
            },
            {
                key: "expandable.traffic.rss.loading",
                de: "Baustellendaten werden geladen ...",
                en: "Loading construction site data ...",
                it: "Caricamento dei dati del cantiere ...",
                nl: "Bouwplaatsgegevens worden geladen...",
                fr: "Données de chantier en cours de chargement ..."
            },
            {
                key: "expandable.traffic.rss.period",
                de: "Zeitraum",
                en: "Period",
                it: "Periodo",
                nl: "Periode",
                fr: "Période"
            },
            {
                key: "expandable.traffic.rss.section",
                de: "Streckenabschnitt",
                en: "Section",
                it: "Sezione",
                nl: "Deel van het traject",
                fr: "Section de trajet"
            },
            {
                key: "expandable.traffic.rss.reason",
                de: "Grund",
                en: "Reason",
                it: "Ragionare",
                nl: "Reden",
                fr: "Raison"
            },
            {
                key: "expandable.traffic.rss.noMessages",
                de: "Derzeit keine Meldungen",
                en: "Currently no messages",
                it: "Attualmente nessun messaggio",
                nl: "Momenteel geen meldingen",
                fr: "Aucun signalement actuellement"
            },
            {
                key: "expandable.traffic.rss.details",
                de: "Details",
                en: "Details",
                it: "Dettagli",
                nl: "Details",
                fr: "Détails"
            },
            {
                key: "expandable.traffic.rss.download",
                de: "Information als PDF Download",
                en: "Information as PDF Download",
                it: "Informazioni come download PDF",
                nl: "Informatie als pdf-download",
                fr: "Information comme téléchargement PDF"
            },
            {
                key: "expandable.traffic.rss.downloadAs",
                de: "Information downloaden als",
                en: "Download information as",
                it: "Scarica informazioni come",
                nl: "Informatie downloaden als",
                fr: "Télécharger les informations comme"
            },
            {
                key: "quiz.question",
                de: "Frage",
                en: "Quesetion",
                it: "Domanda",
                nl: "Vraag",
                fr: "Question"
            },
            {
                key: "quiz.from",
                de: "von",
                en: "from",
                it: "di",
                nl: "van",
                fr: "de"
            },
            {
                key: "quiz.result",
                de: "Ergebnis",
                en: "Result",
                it: "Risultato",
                nl: "Resultaat",
                fr: "Résultat"
            },
            {
                key: "date.month.short",
                de: ["Jän", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
                en: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dez"],
                it: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
                nl: ["Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
                fr: ["Jan", "Fév", "Avr", "Mai", "Jun", "Jui", "Aou", "Sep", "Oct", "Nov", "Déc"]
            },
            {
                key: "date.month.days",
                de: ["SO", "MO", "DI", "MI", "DO", "FR", "SA"],
                en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                it: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
                nl: ["ZO", "MA", "DI", "WO", "DO", "VR", "ZA"],
                fr: ["DIM", "LUN", "MAR", "MER", "JEU", "VEN", "SAM"]
            },
            {
                key: 'discover.vienna',
                de: 'Wien',
                en: 'Vienna',
                it: 'Vienna',
                nl: 'Wenen',
                fr: 'Vienne'
            },
            {
                key: 'discover.lower-austria',
                de: 'Niederösterreich',
                en: 'Lower Austria',
                it: 'Bassa Austria',
                nl: 'Neder-Oostenrijk',
                fr: 'Basse-Autriche'
            },
            {
                key: 'discover.burgenland',
                de: 'Burgenland',
                en: 'Burgenland',
                it: 'Burgenland',
                nl: 'Burgenland',
                fr: 'Burgenland'
            },
            {
                key: 'discover.upper-austria',
                de: 'Oberösterreich',
                en: 'Upper Austria',
                it: 'Alta Austria',
                nl: 'Opper-Oostenrijk',
                fr: 'Haute-Autriche'
            },
            {
                key: 'discover.styria',
                de: 'Steiermark',
                en: 'Styria',
                it: 'Stiria',
                nl: 'Stiermarken',
                fr: 'Styrie'
            },
            {
                key: 'discover.carinthia',
                de: 'Kärnten',
                en: 'Carinthia',
                it: 'Carinzia',
                nl: 'Karinthië',
                fr: 'Carinthie'
            },
            {
                key: 'discover.salzburg',
                de: 'Salzburg',
                en: 'Salzburg',
                it: 'Salisburgo',
                nl: 'Salzburg',
                fr: 'Salsbourg'
            },
            {
                key: 'discover.tyrol',
                de: 'Tirol',
                en: 'Tyrol',
                it: 'Tirolo',
                nl: 'Tirol',
                fr: 'Tyrol'
            },
            {
                key: 'discover.vorarlberg',
                de: 'Vorarlberg',
                en: 'Vorarlberg',
                it: 'Vorarlberg',
                nl: 'Voralberg',
                fr: 'Voralberg'
            },
            {
                key: 'poi.activation.action',
                de: 'Karte aktivieren',
                en: 'Activate map',
                it: 'Attiva la mappa',
                nl: 'Kaart activeren',
                fr: 'Activer la carte'
            },
            {
                key: 'poi.activation.note',
                de: 'Diese Webseite verwendet Google Maps für die Darstellung von Karteninformationen. Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der Maps-Funktionen durch Besucher der Webseiten verarbeitet und genutzt. Weitere Informationen über die Datenverarbeitung durch Google können Sie den Hinweisen zum Datenschutz von Google auf <a href="https://policies.google.com/privacy?hl=de" target="_blank">https://policies.google.com/privacy?hl=de</a> entnehmen. Dort können Sie im Datenschutz-Center auch Ihre Einstellungen verändern, sodass Sie Ihre Daten verwalten und schützen können.',
                en: 'This website uses Google Maps for the presentation of map data. While using Google Maps, Google processes and uses data on the usage of maps functions of visitors. Further information about the data processing by Google can be found on <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a>. You can also adjust your settings in the privacy center there in order to manage and protect your data.',
                it: 'Questo sito web usa Google Maps per la presentazione delle informazioni di mappa. Durante l\'uso di Google Maps Google tratta e usa dati dell\'uso della mappa dai visitori. Più informazioni dell\'elaborazione dati si trovano qua <a href="https://policies.google.com/privacy?hl=it" target="_blank">https://policies.google.com/privacy?hl=it</a>. È possibile cambiare la regiolazione nel centro privacy per amministrare e proteggere i suoi dati.',
                nl: 'Deze website maakt gebruik van Google Maps voor de weergave van kaartinformatie. Tijdens het gebruik van Google Maps worden door Google ook gegevens over het gebruik van de Maps-functies door bezoekers van de websites verwerkt en gebruikt. Meer informatie over de gegevensverwerking door Google is te vinden in de informatie over gegevensbescherming van Google op <a href="https://policies.google.com/privacy?hl=de" target="_blank">https://policies.google.com/privacy?hl=de</a>. Daar kunt u in het gegevensbeschermingscentrum ook uw instellingen veranderen, zodat u uw gegevens kunt beheren en beschermen.',
                fr: 'Ce site web utilise Google Maps pour représenter les informations de carte. Lors de l\'utilisation de Google Maps, Google utilise et traite des données sur l\'utilisation des fonctions Maps par l\'utilisateur du site web. Vous trouverez plus d\'informations sur le traitement des données par Google dans les indications sur la protection de données de Google sur <a href="https://policies.google.com/privacy?hl=de" target="_blank">https://policies.google.com/privacy?hl=de</a>. Là, vous pouvez changer vos paramètres dans le centre de protection des données afin de pouvoir gérer et protéger vos données.'
            },
            { key: "video-activate", de: "Youtube aktivieren", en: "Activate Youtube", it: "Attiva Youtube", nl: "Activeer Youtube", fr: "Activer Youtube" },
            { key: "video-deactivate", de: "Youtube deaktivieren", en: "Deactivate Youtube", it: "Disattiva Youtube", nl: "Deactiveer Youtube", fr: "Désactiver Youtube" },
            { key: "video-more-info", de: "Mehr Informationen anzeigen", en: "Show more information", it: "Mostra maggiori informazioni", nl: "Toon meer informatie", fr: "Afficher plus d'informations" },
            { key: "video-less-info", de: "Weniger Informationen anzeigen", en: "Show less information", it: "Mostra meno informazioni", nl: "Toon minder informatie", fr: "Afficher moins d'informations" },
            {
                key: 'video-disclaimer',
                "de": 'Dieses Video ist im erweiterten Datenschutzmodus von Youtube eingebunden, der das Setzen von Youtube-Cookies solange blockiert, bis ein aktiver Klick auf die Wiedergabe erfolgt. Wir möchten Sie darüber informieren, dass wenn Sie auf den Wiedergabe-Button klicken, Youtube (Google) Cookies auf das von Ihnen verwendete Endgerät setzt, die auch zu einer Analyse des Nutzungsverhaltens zu Marktforschungs- und Marketing-Zwecken verwendet werden können (Third Party Cookies). Wir haben darauf keinen Einfluss. Von uns werden Daten weder an Youtube weitergeben noch erhalten wir von Youtube Daten aufgrund der vorgenannten Cookiesetzung. Näheres zur Cookie-Verwendung durch Youtube finden Sie in der Cookie-Policy von Google unter <a href="https://policies.google.com/technologies/types?hl=de" target="_blank">https://policies.google.com/technologies/types?hl=de</a>.',
                "en": 'This video is integrated in YouTube\'s extended data protection mode, which blocks the setting of YouTube cookies until there is an active click on the playback. We would like to inform you that when you click on the play button, Youtube (Google) sets cookies on the device you are using, which can also be used to analyze usage behavior for market research and marketing purposes (third party cookies ). We have no influence on that. We neither pass on data to YouTube, nor do we receive data from YouTube due to the aforementioned cookie setting. You can find more information on the use of cookies by YouTube in Google\'s cookie policy at <a href="https://policies.google.com/technologies/types?hl=en" target="_blank">https://policies.google.com/technologies/types?hl=en</a>.',
                "it": 'Questo video è integrato nella modalità di protezione dei dati estesa di YouTube, che blocca l\'impostazione dei cookie di YouTube finché non viene attivato un clic sulla riproduzione. Ti informiamo che quando fai clic sul pulsante di riproduzione, Youtube (Google) imposta dei cookie sul dispositivo che stai utilizzando, che possono essere utilizzati anche per analizzare il comportamento di utilizzo per ricerche di mercato e scopi di marketing (cookie di terze parti). Non abbiamo alcuna influenza su questo. Non trasmettiamo dati a YouTube, né riceviamo dati da YouTube a causa della suddetta impostazione dei cookie. Puoi trovare ulteriori informazioni sull\'uso dei cookie da parte di YouTube nella cookie policy di Google su <a href="https://policies.google.com/technologies/types?hl=it" target="_blank">https://policies.google.com/technologies/types?hl=it</a>.',
                "nl": 'Deze video is geïntegreerd in de uitgebreide gegevensbeschermingsmodus van YouTube, die het plaatsen van YouTube-cookies blokkeert totdat er actief op het afspelen wordt geklikt. We willen u erop wijzen dat wanneer u op de afspeelknop klikt, YouTube (Google) cookies plaatst op het apparaat dat u gebruikt, die ook kunnen worden gebruikt om gebruiksgedrag te analyseren voor marktonderzoek en marketingdoeleinden (cookies van derden). Daar hebben wij geen invloed op. We geven geen gegevens door aan YouTube, noch ontvangen we gegevens van YouTube vanwege de bovengenoemde cookie-instelling. Meer informatie over het gebruik van cookies door YouTube vindt u in het cookiebeleid van Google op <a href="https://policies.google.com/technologies/types?hl=nl" target="_blank">https://policies.google.com/technologies/types?hl=nl</a>.',
                "fr": 'Cette vidéo est intégrée au mode de protection des données étendu de YouTube, qui bloque le paramétrage des cookies YouTube jusqu\'à ce qu\'il y ait un clic actif sur la lecture. Nous tenons à vous informer que lorsque vous cliquez sur le bouton de lecture, Youtube (Google) installe des cookies sur l\'appareil que vous utilisez, qui peuvent également être utilisés pour analyser le comportement d\'utilisation à des fins d\'études de marché et de marketing (cookies tiers). Nous n\'avons aucune influence là-dessus. Nous ne transmettons pas de données à YouTube et ne recevons pas de données de YouTube en raison du paramètre de cookie susmentionné. Vous pouvez trouver plus d\'informations sur l\'utilisation des cookies par YouTube dans la politique de cookies de Google à l\'adresse <a href="https://policies.google.com/technologies/types?hl=fr" target="_blank">https://policies.google.com/technologies/types?hl=fr</a>.',
                "bs": 'Ovaj video je integrisan u YouTube prošireni režim zaštite podataka, koji blokira podešavanje YouTube kolačića sve dok se aktivni klik ne reprodukuje. Klikom na dugme za reprodukciju dajete saglasnost da YouTube postavlja kolačiće na uređaj koji koristite, a koji se takođe može koristiti za analizu ponašanja prilikom korištenja u svrhu istraživanja tržišta i marketinške svrhe. Više informacija o upotrebi kolačića od strane YouTube možete pronaći u Google smjernicama za kolačiće na <a href="https://policies.google.com/technologies/types?hl=bs">https://policies.google.com/technologies/types?hl=bs</a>.',
                "hu": 'A videót a Youtube megerősített adatvédelmi módjában ágyaztuk be, amely a Youtube-cookie-kat mindaddig blokkolja, amíg Ön el nem indítja a lejátszást. A lejátszás gombra kattintva beleegyezik abba, hogy a Youtube az Ön által használt eszközön cookie-kat tároljon el, amelyek a felhasználói viselkedés elemzése által piackutatási- és marketing célokra is felhasználhatóak. A Youtube cookie-használatáról további részleteket a Google Cookie-szabályzatában olvashat a <a href="https://policies.google.com/technologies/types?hl=hu">https://policies.google.com/technologies/types?hl=hu weboldalon</a>.',
                "hr": 'Ovaj video je integriran u YouTubeovom proširenom načinu zaštite podataka, koji blokira postavljanje YouTube kolačića, sve dok se aktivno ne klikne na reprodukciju. Klikom na gumb za reprodukciju dajete svoj pristanak da YouTube postavi kolačiće na uređaj koji upotrebljavate, a koji se također mogu koristiti za analizu ponašanja korištenja u svrhu istraživanja tržišta i svrhe marketinga. Više informacija o upotrebi kolačića od strane YouTubea možete pronaći u Googleovim pravilima o kolačićima na <a href="https://policies.google.com/technologies/types?hl=hr">https://policies.google.com/technologies/types?hl=hr</a>.',
                "ru": 'Данное видео включено в расширенный режим защиты данных Youtube, который блокирует сохранение файлов куки Youtube до момента, когда последует активный клик на воспроизведение. Нажатием кнопки воспроизведения вы даете согласие на то, что Youtube сохранит на используемом вами конечном устройстве файлы куки, которые также могут использоваться для анализа пользовательского поведения в целях маркетинговых исследований и маркетинга. Дополнительная информация об использовании Youtube файлов куки содержится в политике использования Гугл файлов куки по ссылке <a href="https://policies.google.com/technologies/types?hl=ru">https://policies.google.com/technologies/types?hl=ru</a>.',
                "ro": 'Acest video este inclus în modul de protecţie a datelor, extins, de la Youtube, care blochează setarea cookie-urilor Youtube, până când are loc un clic activ pe redarea acestora. Dând clic pe butonul de redare, dumneavoastră vă exprimaţi acordul ca Youtube să seteze cookie-urile pe aparatul terminal utilizat de dumneavoastră, care pot să servească şi la analiza comportamentului de utilizare în scopuri de marketing şi de cercetare a pieţei. Informaţii detaliate privind utilizarea Cookie-urilor de către Youtube, le găsiţi în politica privind cookie-urile a companiei Google pe <a href="https://policies.google.com/technologies/types?hl=ro">https://policies.google.com/technologies/types?hl=ro</a>.',
                "cs": 'Toto video je integrováno  v režimu rozšířené ochrany dat YouTube, který blokuje nastavení souborů cookies YouTube, dokud na přehrávání aktivně nekliknete. Kliknutím na tlačítko přehrát dáváte  YouTube  souhlas s umístěním souborů cookies na zařízení, které používáte. To  lze také použít k analýze chování uživatelů pro průzkum trhu a marketingové účely. Další informace o používání souborů cookies YouTube najdete v zásadách používání souborů cookies společnosti Google na adrese <a href="https://policies.google.com/technologies/types?hl=cs">https://policies.google.com/technologies/types?hl=cs</a>.',
                "sl": 'Ta videoposnetek je integriran v YouTubov razširjeni način zaščite podatkov, ki blokira nastavitev piškotkov YouTube, dokler ne kliknete na gumb za predvajanje. S klikom na gumb za predvajanje podate svojo privolitev, da YouTube nastavi piškotke na napravi, ki jo uporabljate, s čimer se lahko analizira tudi vedenje uporabe za tržne raziskave in trženje. Več informacij o uporabi piškotkov v YouTubu najdete v Googlovem pravilniku o piškotkih na <a href="https://policies.google.com/technologies/cookies?hl=sl#types-of-cookies">https://policies.google.com/technologies/cookies?hl=sl#types-of-cookies</a>.'
            },
        ];
        // this.i18n.getProperty("","")
        // self.i18n.getProperty("","")
    }
    I18n.prototype.getLang = function () {
        return this.lang;
    };
    I18n.prototype.getProperty = function (name, defaultValue) {
        var result = this.objects.filter(function (element, index, objetc) {
            if (element.key == name) {
                return true;
            }
        });
        var output = defaultValue;
        if (result.length > 0) {
            if (result[0][this.lang]) {
                output = result[0][this.lang];
            }
        }
        return output;
    };
    return I18n;
}());
exports.I18n = I18n;

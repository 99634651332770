"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Expandable = void 0;
var Expandable = /** @class */ (function () {
    function Expandable(element, onOpen, onClose) {
        var _this = this;
        this.id = Math.random().toString(36).substr(2, 6);
        this.idPrefixLabel = 'expandable-label-';
        this.idPrefixContent = 'expandable-content-';
        this.height = 0;
        this.setOpen = function (onOpen) {
            _this.element.classList.add('expandable-open');
            _this.content.style.display = 'block';
            _this.content.style.opacity = '1';
            _this.content.style.height = 'auto';
            _this.height = _this.content.scrollHeight;
            if (onOpen) {
                onOpen();
            }
            else if (_this.openCallback) {
                _this.openCallback();
            }
        };
        this.setClose = function (onClose) {
            _this.element.classList.remove('expandable-open');
            _this.content.style.display = 'none';
            _this.content.style.opacity = '0.2';
            _this.content.style.height = '0px';
            if (onClose) {
                onClose();
            }
            else if (_this.closeCallback) {
                _this.closeCallback();
            }
        };
        this.element = element;
        this.openCallback = onOpen;
        this.closeCallback = onClose;
        this.handler = this.element.querySelector('a');
        this.content = this.element.querySelector('div');
        this.element.ref = this;
        if (this.handler && this.content) {
            // Only use generated id with prefix if there is not an id, we need this for "Tarifbestimmungen" (automatic scrolling and collapsing)
            if (this.handler.getAttribute('id')) {
                this.id = this.handler.getAttribute('id');
                this.idPrefixLabel = '';
            }
            this.handler.setAttribute('href', '#');
            this.handler.setAttribute('id', "".concat(this.idPrefixLabel).concat(this.id));
            this.handler.setAttribute('role', 'button');
            this.handler.setAttribute('aria-expanded', 'false');
            this.handler.setAttribute('aria-controls', "".concat(this.idPrefixContent).concat(this.id));
            this.content.setAttribute('role', 'region');
            this.content.setAttribute('aria-expanded', 'false');
            this.content.setAttribute('id', "".concat(this.idPrefixContent).concat(this.id));
            this.content.setAttribute('aria-labelledby', "".concat(this.idPrefixLabel).concat(this.id));
            this.content.style.display = 'none';
            this.content.style.height = '0';
            this.content.style.opacity = '0.2';
            this.content.style.overflow = 'hidden';
            this.content.style.transition = "height ".concat(Expandable.ANIMATION_DURATION / 1000, "s ease-in-out, opacity ").concat(Expandable.ANIMATION_DURATION / 1000, "s ease-in-out");
            if (this.navigatedToByAnchor()) {
                this.setOpen();
            }
            window.addEventListener('hashchange', function () {
                if (_this.navigatedToByAnchor()) {
                    _this.setOpen();
                }
            }, false);
            this.handler.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                _this.toggle(onOpen, onClose);
            });
            this.handler.addEventListener('keydown', function (e) {
                if (e.keyCode !== 32)
                    return;
                e.preventDefault();
                _this.toggle(onOpen, onClose);
            });
        }
    }
    ;
    Object.defineProperty(Expandable, "ANIMATION_DURATION", {
        // Animation duration in ms
        get: function () { return 300; },
        enumerable: false,
        configurable: true
    });
    Expandable.prototype.toggle = function (onOpen, onClose) {
        var _this = this;
        // Reset height to px value for animation
        this.content.style.height = this.height + 'px';
        this.content.style.height = window.getComputedStyle(this.content).height;
        if (this.element.classList.contains('expandable-open')) {
            this.handler.setAttribute('aria-expanded', 'false');
            this.content.setAttribute('aria-expanded', 'false');
            setTimeout(function () {
                _this.content.style.display = 'none';
            }, Expandable.ANIMATION_DURATION);
            this.content.style.opacity = '0.2';
            this.content.style.height = '0px';
            this.element.classList.remove('expandable-open');
            if (onClose)
                onClose();
            this.height = 0;
            window.clearTimeout(this.timeoutId); // Clear timeout to prevent errors if expandable is triggered and animation is running
        }
        else {
            this.handler.setAttribute('aria-expanded', 'true');
            this.content.setAttribute('aria-expanded', 'true');
            this.content.style.display = 'block';
            this.content.style.opacity = '1';
            this.content.style.height = this.content.scrollHeight + 'px';
            this.element.classList.add('expandable-open');
            window.getComputedStyle(this.content).display; // Trigger layout
            if (onOpen)
                onOpen();
            this.height = this.content.scrollHeight;
            this.timeoutId = window.setTimeout(function () {
                // Set height to "auto" after animation for nested expandables
                _this.content.style.height = 'auto';
            }, Expandable.ANIMATION_DURATION);
        }
    };
    Expandable.prototype.navigatedToByAnchor = function () {
        return (window.location.hash.length > 0) && (this.element.id && location.hash === '#' + this.element.id);
    };
    return Expandable;
}());
exports.Expandable = Expandable;

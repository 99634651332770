"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoWaterfallDisclaimer = void 0;
var I18n_1 = require("./I18n");
var Observer_1 = require("./Observer");
var VideoWaterfallDisclaimer = /** @class */ (function (_super) {
    __extends(VideoWaterfallDisclaimer, _super);
    function VideoWaterfallDisclaimer(elements, lang) {
        var _this = _super.call(this) || this;
        _this.disclaimerWrapperId = 'waterfall-youtube-disclaimer';
        _this.disclaimerBtnId = 'waterfall-youtube-disclaimer__btn';
        _this.disclaimerCloseBtnId = 'waterfall-youtube-disclaimer__close-btn';
        _this.disclaimerTxtId = 'waterfall-youtube-disclaimer__txt';
        _this.disclaimerMoreBtn = "waterfall-youtube-disclaimer__more-btn";
        _this.localStorageKey = 'oebb-yt-videos';
        _this.elements = elements;
        _this.lang = lang;
        _this.i18n = new I18n_1.I18n(_this.lang);
        _this.createDisclaimer();
        _this.createDisclaimerBtn();
        _this.toggleVideos();
        return _this;
    }
    VideoWaterfallDisclaimer.prototype.toggleVideos = function () {
        this.videosAreEnabled() ? this.activateVideos() : this.deactivateVideos();
    };
    VideoWaterfallDisclaimer.prototype.createDisclaimer = function () {
        var disclaimerDiv = this.createDisclaimerWrapper();
        var disclaimerCloseBtn = this.createDisclaimerCloseBtn();
        var disclaimerTxt = this.createDisclaimerTxt();
        var disclaimerBtn = this.createDisclaimerBtn();
        var disclaimerMoreBtn = this.createDisclaimerMoreBtn();
        disclaimerDiv.appendChild(disclaimerCloseBtn);
        disclaimerDiv.appendChild(disclaimerTxt);
        disclaimerDiv.appendChild(disclaimerBtn);
        disclaimerDiv.appendChild(disclaimerMoreBtn);
        document.getElementsByTagName("footer")[0].after(disclaimerDiv);
        this.disclaimerDiv = disclaimerDiv;
    };
    VideoWaterfallDisclaimer.prototype.createDisclaimerWrapper = function () {
        var disclaimer = document.createElement('div');
        disclaimer.id = this.disclaimerWrapperId;
        disclaimer.setAttribute('role', 'region');
        disclaimer.setAttribute('aria-label', 'YouTube Disclaimer');
        return disclaimer;
    };
    VideoWaterfallDisclaimer.prototype.createDisclaimerCloseBtn = function () {
        var disclaimerCloseBtn = document.createElement('button');
        var self = this;
        disclaimerCloseBtn.id = this.disclaimerCloseBtnId;
        disclaimerCloseBtn.innerHTML = "<span class='ic-close'></span><span class='hide-accessible'>Schließen</span>";
        disclaimerCloseBtn.addEventListener('click', function (e) {
            e.preventDefault();
            document.getElementById(self.disclaimerWrapperId).classList.add('closed');
        });
        this.disclaimerCloseBtn = disclaimerCloseBtn;
        return disclaimerCloseBtn;
    };
    VideoWaterfallDisclaimer.prototype.createDisclaimerTxt = function () {
        var disclaimerTxt = document.createElement('div');
        disclaimerTxt.id = this.disclaimerTxtId;
        disclaimerTxt.innerHTML = this.i18n.getProperty('video-disclaimer', '');
        this.disclaimerTxtElement = disclaimerTxt;
        return disclaimerTxt;
    };
    VideoWaterfallDisclaimer.prototype.createDisclaimerMoreBtn = function () {
        var disclaimerMoreInfo = document.createElement('button');
        disclaimerMoreInfo.classList.add('md-button', 'md-button--link');
        disclaimerMoreInfo.id = (this.disclaimerMoreBtn);
        disclaimerMoreInfo.textContent = this.i18n.getProperty("video-more-info", "mehr Informationen");
        var self = this;
        disclaimerMoreInfo.addEventListener('click', function (e) {
            e.preventDefault();
            if (self.disclaimerDiv.classList.contains("open")) {
                self.disclaimerDiv.classList.remove("open");
                disclaimerMoreInfo.textContent = self.i18n.getProperty("video-more-info", "mehr Informationen anzeigen");
            }
            else {
                self.disclaimerDiv.classList.add("open");
                disclaimerMoreInfo.textContent = self.i18n.getProperty("video-less-info", "weniger Informationen anzeigen");
            }
        });
        return disclaimerMoreInfo;
    };
    VideoWaterfallDisclaimer.prototype.setDisclaimerBtnTxt = function () {
        var btnTxt;
        if (this.videosAreEnabled()) {
            btnTxt = this.i18n.getProperty('video-deactivate', "YouTube deaktivieren");
        }
        else {
            btnTxt = this.i18n.getProperty('video-activate', "YouTube aktivieren");
        }
        document.getElementById(this.disclaimerBtnId).textContent = btnTxt;
    };
    VideoWaterfallDisclaimer.prototype.createDisclaimerBtn = function () {
        var _this = this;
        var disclaimerBtn = document.createElement("button");
        disclaimerBtn.classList.add('js-disclaimer-btn', 'action-btn', 'action-btn--right');
        disclaimerBtn.id = this.disclaimerBtnId;
        disclaimerBtn.textContent = this.i18n.getProperty("video-deactivate", "Youtube deaktivieren");
        disclaimerBtn.addEventListener("click", function (event) {
            _this.setLocalStorage();
            _this.toggleVideos();
        });
        this.disclaimerBtn = disclaimerBtn;
        return disclaimerBtn;
    };
    VideoWaterfallDisclaimer.prototype.activateVideos = function () {
        for (var i = 0; i < this.elements.length; i++) {
            var videoElement = this.elements.item(i);
            videoElement.classList.add('video--activated');
            var frame = videoElement.querySelector('iframe');
            if (frame) {
                videoElement.querySelector('iframe').style.pointerEvents = 'unset';
                videoElement.querySelector('iframe').src = videoElement.getAttribute('data-video-url');
            }
            videoElement.classList.add('video--ready');
        }
        this.setDisclaimerBtnTxt();
    };
    VideoWaterfallDisclaimer.prototype.deactivateVideos = function () {
        for (var i = 0; i < this.elements.length; i++) {
            var videoElement = this.elements.item(i);
            videoElement.classList.remove('video--activated');
            var frame = videoElement.querySelector('iframe');
            if (frame) {
                videoElement.querySelector('iframe').src = "";
                videoElement.querySelector('iframe').style.pointerEvents = 'none';
            }
            videoElement.classList.add('video--ready');
        }
        this.setDisclaimerBtnTxt();
    };
    VideoWaterfallDisclaimer.prototype.setLocalStorage = function () {
        if (this.videosAreEnabled()) {
            localStorage.removeItem(this.localStorageKey);
        }
        else {
            localStorage.setItem(this.localStorageKey, "true");
        }
    };
    VideoWaterfallDisclaimer.prototype.videosAreEnabled = function () {
        return localStorage.getItem(this.localStorageKey) ? true : false;
    };
    VideoWaterfallDisclaimer.prototype.notify = function () {
        this.toggleVideos();
    };
    return VideoWaterfallDisclaimer;
}(Observer_1.Observer));
exports.VideoWaterfallDisclaimer = VideoWaterfallDisclaimer;

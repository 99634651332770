"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RowEqualizer = void 0;
var RowEqualizer = /** @class */ (function () {
    function RowEqualizer(modules) {
        var _this = this;
        this.isEqualized = false;
        this.modules = modules;
        var resizeTimer;
        window.addEventListener('resize', function () {
            clearTimeout(resizeTimer);
            resizeTimer = window.setTimeout(function () {
                if (_this.isEqualized && window.innerWidth <= RowEqualizer.COLUMN_BREAK_POINT) {
                    _this.reset();
                }
                else if (!_this.isEqualized && window.innerWidth > RowEqualizer.COLUMN_BREAK_POINT) {
                    _this.startEqualizer();
                }
            }, RowEqualizer.RENDER_TIMEOUT_RESIZE);
        });
        if (window.innerWidth > RowEqualizer.COLUMN_BREAK_POINT)
            this.startEqualizer();
    }
    Object.defineProperty(RowEqualizer, "COLUMN_BREAK_POINT", {
        get: function () { return 1024; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RowEqualizer, "RENDER_TIMEOUT_INITIAL", {
        get: function () { return 100; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RowEqualizer, "RENDER_TIMEOUT_RESIZE", {
        get: function () { return 200; },
        enumerable: false,
        configurable: true
    });
    RowEqualizer.prototype.startEqualizer = function () {
        var _this = this;
        setTimeout(function () { return _this.equalize(); }, RowEqualizer.RENDER_TIMEOUT_INITIAL);
    };
    RowEqualizer.prototype.equalize = function () {
        // Loop over all modules
        for (var i = 0; i < this.modules.length; i++) {
            var cols = this.modules[i].children;
            var mostChildrenInACol = 0;
            var colElements = {};
            // Find most elements in a column and store column elements in an object
            for (var j = 0; j < cols.length; j++) {
                if (mostChildrenInACol < cols[j].children.length)
                    mostChildrenInACol = cols[j].children.length;
                colElements[j] = cols[j].children;
            }
            // Loop as long as
            for (var j = 0; j < mostChildrenInACol; j++) {
                var h = 0;
                // Loop to find highest element
                for (var _i = 0, _a = Object.keys(colElements); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (colElements[key][j] && colElements[key][j].offsetHeight > h) {
                        h = colElements[key][j].offsetHeight;
                    }
                }
                // Loop to equal all elements
                for (var _b = 0, _c = Object.keys(colElements); _b < _c.length; _b++) {
                    var key = _c[_b];
                    if (colElements[key][j]) {
                        colElements[key][j].style.height = h + 'px';
                    }
                    else {
                        // There are nor more elements to set height
                        break;
                    }
                }
            }
        }
        this.isEqualized = true;
    };
    RowEqualizer.prototype.reset = function () {
        for (var i = 0; i < this.modules.length; i++) {
            for (var j = 0; j < this.modules[i].children.length; j++) {
                for (var k = 0; k < this.modules[i].children[j].children.length; k++) {
                    this.modules[i].children[j].children[k].style.height = 'auto';
                }
            }
        }
        this.isEqualized = false;
    };
    return RowEqualizer;
}());
exports.RowEqualizer = RowEqualizer;

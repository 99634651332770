"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpandableTrafficRss = exports.TrafficRssFilterMethod = void 0;
var Expandable_1 = require("./Expandable");
var TrafficRssFilterMethod;
(function (TrafficRssFilterMethod) {
    TrafficRssFilterMethod[TrafficRssFilterMethod["IN"] = 0] = "IN";
    TrafficRssFilterMethod[TrafficRssFilterMethod["NOT"] = 1] = "NOT";
})(TrafficRssFilterMethod = exports.TrafficRssFilterMethod || (exports.TrafficRssFilterMethod = {}));
;
var ExpandableTrafficRss = /** @class */ (function (_super) {
    __extends(ExpandableTrafficRss, _super);
    function ExpandableTrafficRss(element, i18n, filterMethod, filterKeywords, onOpen, onClose) {
        var _this = _super.call(this, element, onOpen, onClose) || this;
        _this.filterMethod = TrafficRssFilterMethod.IN;
        _this.filterKeywords = [];
        _this.items = [];
        _this.i18n = i18n;
        _this.filterMethod = filterMethod;
        _this.filterKeywords = filterKeywords;
        _this.url = element.getAttribute('data-url');
        _this.region = element.getAttribute('data-region');
        _this.body = element.querySelector('.js-expandable-traffic-rss-body');
        return _this;
    }
    ExpandableTrafficRss.prototype.loadItemsAndRender = function () {
        var _this = this;
        this.body.innerHTML = "<p>".concat(this.i18n.getProperty('expandable.traffic.rss.loading', 'Baustellendaten werden geladen ...'), "</p>");
        var xhr = new XMLHttpRequest();
        xhr.open('GET', "".concat(this.url, "/").concat(this.region), true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                _this.items = JSON.parse(xhr.response);
                _this.render();
            }
        };
        xhr.send(null);
    };
    ExpandableTrafficRss.prototype.setItems = function (items) {
        this.items = items;
    };
    ExpandableTrafficRss.prototype.sortItemsByPeriod = function () {
        var _this = this;
        this.items.forEach(function (item, i) {
            // Search for date period
            var periodMatch = item['description'].match(/\d{2}\.\d{2}.\d{4}\s-\s\d{2}\.\d{2}\.\d{4}/);
            var period;
            var startDate;
            if (periodMatch) {
                // Date period found
                period = periodMatch[0];
                var _a = period.split(' - ')[0].split('.'), day = _a[0], month = _a[1], year = _a[2];
                startDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12, 0, 0, 0);
            }
            else {
                // Date period not found, search for normal date
                periodMatch = item['description'].match(/\d{2}\.\d{2}.\d{4}/);
                // Date found
                if (periodMatch) {
                    period = periodMatch[0];
                    var _b = period.split('.'), day = _b[0], month = _b[1], year = _b[2];
                    startDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12, 0, 0, 0);
                }
            }
            // Set values, empty strings as fallback
            period ? item.period = period : item.period = '';
            startDate ? item.startDate = startDate : item.startDate = '';
            _this.items[i] = item;
        });
        this.items.sort(function (a, b) {
            if (a.startDate == "") {
                return b.startDate.getTime();
            }
            else if (b.startDate == "") {
                return a.startDate.getTime();
            }
            return a.startDate.getTime() - b.startDate.getTime();
        });
    };
    ExpandableTrafficRss.prototype.filterItems = function () {
        var _this = this;
        var filteredItems = [];
        if (this.filterMethod === TrafficRssFilterMethod.IN) {
            filteredItems = this.items.filter(function (item) {
                var filterMatch = _this.filterKeywords.some(function (keyword) {
                    if (item['title'].toLowerCase().indexOf(keyword.toLowerCase()) > -1)
                        return true;
                });
                if (filterMatch)
                    return true;
            });
        }
        if (this.filterMethod === TrafficRssFilterMethod.NOT) {
            filteredItems = this.items.filter(function (item) {
                var filterMatch = _this.filterKeywords.every(function (keyword) {
                    if (item['title'].toLowerCase().indexOf(keyword.toLowerCase()) === -1)
                        return true;
                });
                if (filterMatch)
                    return true;
            });
        }
        this.items = filteredItems;
    };
    ExpandableTrafficRss.prototype.renderItems = function () {
        var tbody = document.createElement('tbody');
        for (var i = 0; i < this.items.length; i++) {
            var period = this.items[i]['period'];
            var title = this.items[i]['title'];
            var titleSplit = title.split(':');
            var section = titleSplit.length > 1 ? titleSplit[1].replace('<=>', '< >').replace('=>', '>').replace('<=', '<') : '';
            var reason = titleSplit.length > 0 ? titleSplit[0] : '';
            var details = this.items[i]['description'].match(/(https:\/\/kundeninformation-pv.oebb.at\/dam\/kundeninformation\/extern)(.*)\.pdf/);
            tbody.innerHTML += "\n                <tr>\n                    <td>".concat(period, "</td>\n                    <td>").concat(section, "</td>\n                    <td>").concat(reason, "</td>\n                    <td>\n                        ").concat(details && details.length
                ? "<a href=\"".concat(details[0], "\" title=\"").concat(this.i18n.getProperty('expandable.traffic.rss.download', 'Information als PDF Download'), "\" class=\"expandable-traffic-rss-download\" target=\"_blank\">\n                                <span class=\"ic-download\" aria-hidden=\"true\"></span>\n                                <span class=\"screen-reader-text\">").concat(this.i18n.getProperty('expandable.traffic.rss.downloadAs', 'Information downloaden als'), "</span>\n                                PDF\n                            </a>")
                : '-', "\n                    </td>\n                </tr>\n            ");
        }
        return tbody.innerHTML;
    };
    ExpandableTrafficRss.prototype.render = function () {
        this.filterItems();
        this.sortItemsByPeriod();
        if (!this.items.length) {
            return this.body.innerHTML = "\n                <p>".concat(this.i18n.getProperty('expandable.traffic.rss.noMessages', 'Derzeit keine Meldungen'), "</p>\n            ");
        }
        return this.body.innerHTML = "\n            <table>\n                <thead>\n                    <tr>\n                        <th>".concat(this.i18n.getProperty('expandable.traffic.rss.period', 'Zeitraum'), "</th>\n                        <th>").concat(this.i18n.getProperty('expandable.traffic.rss.section', 'Streckenabschnitt'), "</th>\n                        <th>").concat(this.i18n.getProperty('expandable.traffic.rss.reason', 'Grund'), "</th>\n                        <th>").concat(this.i18n.getProperty('expandable.traffic.rss.details', 'Details'), "</th>\n                    </tr>\n                </thead>\n                ").concat(this.renderItems(), "\n            </table>\n        ");
    };
    return ExpandableTrafficRss;
}(Expandable_1.Expandable));
exports.ExpandableTrafficRss = ExpandableTrafficRss;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.initIframeOverlay = exports.IframeOverlay = void 0;
var disclaimerUtils = require("./disclaimerUtils");
var Observer_1 = require("./Observer");
var IframeOverlay = /** @class */ (function (_super) {
    __extends(IframeOverlay, _super);
    function IframeOverlay(openerLink, lang) {
        var _this = _super.call(this) || this;
        _this.openerLink = openerLink;
        _this.lang = lang;
        var self = _this;
        _this.isYoutubeFrame = _this.openerLink.classList.contains('overlay--youtube');
        if (!document.getElementById("iframe-overlay")) {
            _this.overlay = document.createElement("div");
            document.body.appendChild(_this.overlay);
            _this.overlay.classList.add("iframe-overlay");
            _this.overlay.id = "iframe-overlay";
            _this.iframeHolder = document.createElement("div");
            _this.overlay.appendChild(_this.iframeHolder);
            _this.iframeHolder.classList.add("iframe-holder");
            _this.overlayTitle = document.createElement("h3");
            // this.iframeHolder.appendChild(this.overlayTitle)
            _this.closeLink = document.createElement("a");
            _this.closeLink.href = "#";
            _this.closeLink.classList.add('close-button');
            _this.iframeHolder.appendChild(_this.closeLink);
            _this.iframe = document.createElement("iframe");
            _this.iframe.setAttribute("allowfullscreen", "true");
            _this.iframe.setAttribute("allowtransparency", "true");
            _this.iframeHolder.appendChild(_this.iframe);
            _this.closeLink.innerHTML = "<span class=\"ic-close\" aria-hidden=\"true\"></span>";
        }
        else {
            _this.overlay = document.querySelector(".iframe-overlay");
            _this.iframeHolder = document.querySelector(".iframe-holder");
            _this.iframe = _this.overlay.querySelector("iframe");
            // this.overlayTitle = this.overlay.querySelector("h3") as HTMLHeadingElement
            _this.overlayTitle = document.createElement("h3");
            _this.closeLink = _this.overlay.querySelector("a");
        }
        // Hole Daren von Attributen
        _this.openerLink.addEventListener("click", function (event) {
            event.preventDefault();
            //event.stopPropagation();
            self.overlayTitle.innerText = self.openerLink.getAttribute("data-overlay-title");
            self.iframeHolder.insertBefore(self.overlayTitle, self.iframeHolder.firstChild);
            self.overlay.classList.add(self.openerLink.getAttribute("data-overlay-class"));
            self.overlay.style.display = "block";
            if (self.isYoutubeFrame) {
                self.setupDisclaimerHTML();
                if (disclaimerUtils.isYoutubeEnabled()) {
                    self.setIframeSrc();
                }
                else {
                    self.iframeHolder.classList.add('disclaimer-active');
                }
            }
            else {
                self.setIframeSrc();
            }
        });
        _this.closeLink.addEventListener("click", function (event) {
            event.preventDefault();
            event.stopPropagation();
            self.overlay.style.display = "none";
            self.overlay.classList.remove(self.openerLink.getAttribute("data-overlay-class"));
            self.iframe.src = "";
            // self.overlayTitle.innerText = ""
            if (self.overlayTitle.parentNode)
                self.overlayTitle.parentNode.removeChild(self.overlayTitle);
            if (self.isYoutubeFrame) {
                self.iframeHolder.classList.remove('disclaimer-active');
                if (self.iframeHolder.querySelector('.video__disclaimer'))
                    self.disclaimer.parentNode.removeChild(self.disclaimer);
                if (self.iframeHolder.querySelector('.js-disclaimer-btn'))
                    self.disclaimerBtn.parentNode.removeChild(self.disclaimerBtn);
            }
        });
        return _this;
    }
    IframeOverlay.prototype.setIframeSrc = function () {
        this.iframe.src = this.openerLink.getAttribute("data-overlay-url");
    };
    IframeOverlay.prototype.setupDisclaimerHTML = function () {
        var _this = this;
        if (!this.iframeHolder.querySelector('.video__disclaimer')) {
            this.disclaimer = disclaimerUtils.createDisclaimerTxt(this.lang);
            this.iframeHolder.appendChild(this.disclaimer);
        }
        if (!this.iframeHolder.querySelector('.js-disclaimer-btn')) {
            this.disclaimerBtn = disclaimerUtils.createDisclaimerBtn();
            disclaimerUtils.changeDisclaimerBtnText(this.disclaimerBtn, this.lang);
            this.disclaimerBtn.addEventListener('click', function (e) {
                e.preventDefault();
                disclaimerUtils.toggleYoutubeLocalstorage();
                _this.toggleDisclaimer();
                if (disclaimerUtils.isYoutubeEnabled()) {
                    _this.setIframeSrc();
                }
                _this.notifyObservers();
            });
            this.iframeHolder.appendChild(this.disclaimerBtn);
        }
    };
    IframeOverlay.prototype.toggleDisclaimer = function () {
        if (disclaimerUtils.isYoutubeEnabled()) {
            this.iframeHolder.classList.remove('disclaimer-active');
        }
        else {
            this.iframeHolder.classList.add('disclaimer-active');
            this.iframe.src = "";
        }
        disclaimerUtils.changeDisclaimerBtnText(this.disclaimerBtn, this.lang);
    };
    IframeOverlay.prototype.notify = function () {
        this.toggleDisclaimer();
    };
    return IframeOverlay;
}(Observer_1.Observer));
exports.IframeOverlay = IframeOverlay;
function initIframeOverlay(currentLang, video) {
    if (document.querySelector('a[data-overlay-url]')) {
        var overlays = document.querySelectorAll('a[data-overlay-url]');
        for (var i = 0; i < overlays.length; i++) {
            var overLay = new IframeOverlay(overlays.item(i), currentLang);
            if (video) {
                video.addObserver(overLay);
                overLay.addObserver(video);
            }
        }
    }
}
exports.initIframeOverlay = initIframeOverlay;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpandableConstruction = void 0;
var Expandable_1 = require("./Expandable");
var ExpandableConstruction = /** @class */ (function (_super) {
    __extends(ExpandableConstruction, _super);
    function ExpandableConstruction(element, i18n, region, onOpen, onClose) {
        var _this = _super.call(this, element, onOpen, onClose) || this;
        _this.items = [];
        _this.i18n = i18n;
        _this.url = element.getAttribute('data-url');
        _this.region = element.getAttribute('data-region');
        _this.body = element.querySelector('.js-expandable-traffic-rss-body');
        return _this;
    }
    ExpandableConstruction.prototype.loadItemsAndRender = function () {
        var _this = this;
        this.body.innerHTML = "<p>".concat(this.i18n.getProperty('expandable.traffic.rss.loading', 'Baustellendaten werden geladen ...'), "</p>");
        var xhr = new XMLHttpRequest();
        xhr.open('GET', "".concat(this.url), true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                _this.items = JSON.parse(xhr.response);
                _this.render();
            }
        };
        xhr.send(null);
    };
    ExpandableConstruction.prototype.setItems = function (items) {
        this.items = items;
    };
    ExpandableConstruction.prototype.formatDate = function (date) {
        return new Date(date).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" });
    };
    ExpandableConstruction.prototype.renderItems = function () {
        var tbody = document.createElement('tbody');
        for (var i = 0; i < this.items.length; i++) {
            var startDate = this.formatDate(this.items[i]['startDate']);
            var endDate = this.formatDate(this.items[i]['endDate']);
            var title = this.items[i]['title'];
            var section = this.items[i]['fromStop'] == this.items[i]['toStop'] ? this.items[i]['toStop'] : this.items[i]['fromStop'] + " <=> " + this.items[i]['toStop'];
            var link = this.linkRender(this.items[i]['link']);
            tbody.innerHTML += "\n                <tr>\n                    <td>".concat(startDate, " - ").concat(endDate, "</td>\n                    <td>").concat(section, "</td>\n                    <td>").concat(title, "</td>\n                    <td>").concat(link, "</td>\n                </tr>\n            ");
        }
        return tbody.innerHTML;
    };
    ExpandableConstruction.prototype.linkRender = function (link) {
        if (!link) {
            return "-";
        }
        return "<a href=\"".concat(link, "\" title=\"").concat(this.i18n.getProperty('expandable.traffic.rss.download', 'Information als PDF Download'), "\" class=\"expandable-traffic-rss-download\" target=\"_blank\">\n                    <span class=\"ic-download\" aria-hidden=\"true\"></span>\n                    <span class=\"screen-reader-text\">").concat(this.i18n.getProperty('expandable.traffic.rss.downloadAs', 'Information downloaden als'), "</span>\n                    PDF\n                </a>");
    };
    ExpandableConstruction.prototype.render = function () {
        if (!this.items.length) {
            return this.body.innerHTML = "\n                <p>".concat(this.i18n.getProperty('expandable.traffic.rss.noMessages', 'Derzeit keine Meldungen'), "</p>\n            ");
        }
        return this.body.innerHTML = "\n            <table>\n                <thead>\n                    <tr>\n                        <th>".concat(this.i18n.getProperty('expandable.traffic.rss.period', 'Zeitraum'), "</th>\n                        <th>").concat(this.i18n.getProperty('expandable.traffic.rss.section', 'Streckenabschnitt'), "</th>\n                        <th>").concat(this.i18n.getProperty('expandable.traffic.rss.reason', 'Grund'), "</th>\n                        <th>").concat(this.i18n.getProperty('expandable.traffic.rss.details', 'Details'), "</th>\n                    </tr>\n                </thead>\n                ").concat(this.renderItems(), "\n            </table>\n        ");
    };
    return ExpandableConstruction;
}(Expandable_1.Expandable));
exports.ExpandableConstruction = ExpandableConstruction;

"use strict";
// import { isIE } from './utils';
Object.defineProperty(exports, "__esModule", { value: true });
exports.isChatButtonVisible = exports.setHiddenState = exports.initChat = void 0;
var utils_1 = require("./utils");
function initChat() {
    if (!document.querySelector('.js-chat-lcw-start'))
        return;
    var chatbotButton = document.querySelector('.js-chat-lcw-start');
    var skipLinks = document.querySelector('.skip-links');
    if (skipLinks) {
        var link = document.createElement("a");
        link.href = "#".concat(chatbotButton.getAttribute('id'));
        link.className = "screen-reader-text";
        link.accessKey = "2";
        link.textContent = "".concat(chatbotButton.dataset.skipLinkMessage, " (Alt+2)");
        skipLinks.appendChild(link);
    }
    var chatbotButtonTextContent;
    if (!isChatButtonVisible(chatbotButton.dataset.availableFrom ? parseInt(chatbotButton.dataset.availableFrom) : 0, chatbotButton.dataset.availableTo ? parseInt(chatbotButton.dataset.availableTo) : 24)) {
        var offlineTitle = document.createElement('p');
        offlineTitle.style.fontStyle = 'italic';
        offlineTitle.style.fontSize = '14px';
        offlineTitle.style.opacity = '0.8';
        offlineTitle.textContent = chatbotButton.dataset.offlineTitle;
        chatbotButton.appendChild(offlineTitle);
        chatbotButton.insertAdjacentHTML('afterend', "\n            <div class=\"chat-wc-offline-message js-chat-wc-offline-message\">\n                <button class=\"js-chat-wc-offline-message-close\">\n                    <span class=\"ic-close\" aria-hidden=\"true\"></span>\n                    <span class=\"screen-reader-text\">".concat(chatbotButton.dataset.closeMessage, "</span>\n                </button>\n                <p>").concat(chatbotButton.dataset.offlineMessage, "</p>\n            </div>\n        "));
        chatbotButton.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector('.js-chat-wc-offline-message').classList.add('chat-wc-offline-message--open');
        });
        document.querySelector('.js-chat-wc-offline-message-close').addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector('.js-chat-wc-offline-message').classList.remove('chat-wc-offline-message--open');
        });
        return;
    }
    var dsgvoDisclaimer = document.querySelector('.js-chat-dsgvo-disclaimer');
    var chatbotAlternative = document.querySelector('.js-chat-dsgvo-alternative');
    var disclaimerCloseBtn = document.querySelector('.js-btn-close-disclaimer');
    var alternativeCloseBtn = document.querySelector('.js-btn-close-alternative');
    var dsgvoConfirmButton = document.querySelector('.js-chat-dsgvo-confirm');
    var dsgvoCancleButton = document.querySelector('.js-chat-dsgvo-cancel');
    var openChat = function () {
        Microsoft.Omnichannel.LiveChatWidget.SDK.startChat();
    };
    disclaimerCloseBtn.addEventListener('click', function (e) {
        e.preventDefault();
        this.parentElement.classList.remove('chat-dsgvo-popup--visible');
        setHiddenState(dsgvoDisclaimer);
        chatbotButton.focus();
    });
    alternativeCloseBtn.addEventListener('click', function (e) {
        e.preventDefault();
        this.parentElement.classList.remove('chat-dsgvo-popup--visible');
        setHiddenState(chatbotAlternative);
        chatbotButton.focus();
    });
    chatbotButton.addEventListener('click', function (e) {
        e.preventDefault();
        dsgvoDisclaimer.hidden = false;
        setTimeout(function () { return dsgvoDisclaimer.classList.add('chat-dsgvo-popup--visible'); }); // Timeout to wait for "hidden" attribute
        disclaimerCloseBtn.focus();
    });
    dsgvoConfirmButton.addEventListener('click', function (e) {
        e.preventDefault();
        dsgvoDisclaimer.classList.remove('chat-dsgvo-popup--visible');
        setHiddenState(dsgvoDisclaimer);
        if (document.querySelector('#Microsoft_Omnichannel_LCWidget')) {
            openChat();
        }
        else {
            var scriptElement = document.createElement('script');
            scriptElement.async = true;
            scriptElement.defer = true;
            scriptElement.src = 'https://oc-cdn-public-eur.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js';
            scriptElement.id = 'Microsoft_Omnichannel_LCWidget';
            scriptElement.dataset.appId = chatbotButton.dataset.appId ? chatbotButton.dataset.appId : '8a73df4a-65e6-4ad6-9d48-65ea0a096c1d';
            scriptElement.dataset.orgId = chatbotButton.dataset.orgId ? chatbotButton.dataset.orgId : '99f9ea5c-45cf-4bf3-94e9-7e34c9fa8d69';
            scriptElement.dataset.orgUrl = chatbotButton.dataset.orgUrl ? chatbotButton.dataset.orgUrl : 'https://org5563f30c-crm4.omnichannelengagementhub.com';
            if (chatbotButton.dataset.lcwVersion)
                scriptElement.dataset.lcwVersion = chatbotButton.dataset.lcwVersion;
            // scriptElement.dataset.suggestedActionLayout = 'stacked'; // If this is activated, color does not work anymore for suggested actions
            scriptElement.dataset.colorOverride = '#e2002a';
            scriptElement.dataset.fontFamilyOverride = 'Arial, Helvetica, sans-serif';
            // scriptElement.dataset.hideChatButton = 'true'; // Leaves hidden IFrame and makes areas behind unclickable
            document.body.appendChild(scriptElement);
            chatbotButtonTextContent = chatbotButton.querySelector('span').textContent;
            chatbotButton.querySelector('span').textContent = chatbotButton.dataset.loadingMessage;
        }
    });
    dsgvoCancleButton.addEventListener('click', function (e) {
        e.preventDefault();
        dsgvoDisclaimer.classList.remove('chat-dsgvo-popup--visible');
        setHiddenState(dsgvoDisclaimer);
        chatbotAlternative.hidden = false;
        setTimeout(function () { return chatbotAlternative.classList.add('chat-dsgvo-popup--visible'); }); // Timeout to wait for "hidden" attribute
        alternativeCloseBtn.focus();
        if (document.querySelector('#Microsoft_Omnichannel_LCWidget')) {
            document.querySelector('#Microsoft_Omnichannel_LCWidget').remove();
            document.querySelector('#Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window').remove();
        }
    });
    window.addEventListener("lcw:ready", function handleLivechatReadyEvent() {
        chatbotButton.querySelector('span').textContent = chatbotButtonTextContent;
        chatbotButton.style.display = 'none';
        openChat();
    });
    if (chatbotButton.dataset.textMobileFadeOut) {
        (0, utils_1.onScrollInterval)(function (latestScrollY, clearScrollInterval) {
            if (latestScrollY > 300) {
                clearScrollInterval();
                chatbotButton.classList.add('chat-wc-button--text-mobile-fade-out');
            }
        });
    }
}
exports.initChat = initChat;
function setHiddenState(element) {
    setTimeout(function () {
        if (element.classList.contains('chat-dsgvo-popup--visible'))
            return;
        element.hidden = true;
    }, 1000);
}
exports.setHiddenState = setHiddenState;
function isChatButtonVisible(fromHours, toHours) {
    var from = new Date(new Date().getFullYear(), (3 - 1), 29); // Start of DST in Europe: 29.03.
    var to = new Date(new Date().getFullYear(), (10 - 1), 25); // End of DST in Europe 25.10.
    var now = new Date();
    var offset = '+1';
    if (now >= from && now <= to)
        offset = '+2';
    var utc = now.getTime() + (now.getTimezoneOffset() * 60000);
    var viennaHours = new Date(utc + (3600000 * offset)).getHours();
    if (viennaHours >= fromHours && viennaHours < toHours)
        return true;
    return false;
}
exports.isChatButtonVisible = isChatButtonVisible;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrainEquipment = void 0;
var TrainEquipment = /** @class */ (function () {
    function TrainEquipment() {
        var trainEquipment = document.querySelector('.js-train-equipment');
        var trainEquipmentScrollPanel = document.querySelector(".train-equipment-scrollpanel");
        var equipmentScrollRight;
        var equipmentScrollLeft;
        var firstLinkLeftCenter = 885;
        window.addEventListener('scroll', slideToCenter);
        function slideToCenter() {
            var boundings = trainEquipmentScrollPanel.getBoundingClientRect();
            if (trainEquipmentScrollPanel.querySelector('.train-equipment-content a')) {
                var firstLinkBoundings = trainEquipmentScrollPanel.querySelector('.train-equipment-content a').getBoundingClientRect();
                firstLinkLeftCenter = (firstLinkBoundings.left + (firstLinkBoundings.width / 2)) - boundings.left;
            }
            if (trainEquipment.dataset.scrollPosition) {
                firstLinkLeftCenter = parseInt(trainEquipment.dataset.scrollPosition);
            }
            if (window.innerHeight - boundings.height - boundings.top > 0) {
                var myNewLeft = Math.round(firstLinkLeftCenter - boundings.width / 2);
                if (trainEquipmentScrollPanel.scrollLeft < myNewLeft) {
                    scrollToPositionValue = Math.ceil(myNewLeft);
                    window.requestAnimationFrame(scrollToPosition);
                    window.removeEventListener('scroll', slideToCenter);
                }
            }
        }
        setTimeout(function () { return slideToCenter(); }, 1000);
        function doTrainEquipmentPosition() {
            var parentPos = trainEquipmentScrollPanel.getBoundingClientRect();
            for (var i = 0; i < trainEquipmentItems.length; i++) {
                var pos = trainEquipmentItems[i].getBoundingClientRect();
                if (pos.left < parentPos.left + 5 || pos.left > (parentPos.left + parentPos.width + 100 - pos.width)) {
                    trainEquipmentItems[i].classList.remove("show");
                }
                else {
                    trainEquipmentItems[i].classList.add("show");
                }
            }
            if (trainEquipmentScrollPanel.scrollLeft <= 0) {
                trainEquipmentBackwardLink.style.display = 'none';
                window.cancelAnimationFrame(equipmentScrollRight);
                window.cancelAnimationFrame(equipmentScrollLeft);
            }
            else {
                trainEquipmentBackwardLink.style.display = 'block';
            }
            if (trainEquipmentScrollPanel.offsetWidth + trainEquipmentScrollPanel.scrollLeft + 4 < trainEquipmentScrollPanel.scrollWidth) {
                trainEquipmentForwardLink.style.display = 'block';
            }
            else {
                trainEquipmentForwardLink.style.display = 'none';
                window.cancelAnimationFrame(equipmentScrollRight);
                window.cancelAnimationFrame(equipmentScrollLeft);
            }
        }
        var scrollToPositionValue = 0;
        var scrollValue = 5;
        var scrollToPositionValue;
        function scrollToPosition() {
            if (scrollToPositionValue < trainEquipmentScrollPanel.scrollLeft) {
                if (scrollToPositionValue < trainEquipmentScrollPanel.scrollLeft - scrollValue) {
                    trainEquipmentScrollPanel.scrollLeft = trainEquipmentScrollPanel.scrollLeft - scrollValue;
                }
                else {
                    trainEquipmentScrollPanel.scrollLeft = scrollToPositionValue;
                }
            }
            else {
                if (scrollToPositionValue > trainEquipmentScrollPanel.scrollLeft + scrollValue) {
                    trainEquipmentScrollPanel.scrollLeft = trainEquipmentScrollPanel.scrollLeft + scrollValue;
                }
                else {
                    trainEquipmentScrollPanel.scrollLeft = scrollToPositionValue;
                }
            }
            if (Math.ceil(trainEquipmentScrollPanel.scrollLeft) < scrollToPositionValue) {
                window.requestAnimationFrame(scrollToPosition);
            }
        }
        function scrollToRight() {
            trainEquipmentScrollPanel.scrollLeft = trainEquipmentScrollPanel.scrollLeft + scrollValue;
            equipmentScrollRight = window.requestAnimationFrame(scrollToRight);
        }
        function scrollToLeft() {
            if (trainEquipmentScrollPanel.scrollLeft - scrollValue > 0) {
                trainEquipmentScrollPanel.scrollLeft = trainEquipmentScrollPanel.scrollLeft - scrollValue;
            }
            else {
                trainEquipmentScrollPanel.scrollLeft = 0;
            }
            equipmentScrollLeft = window.requestAnimationFrame(scrollToLeft);
        }
        if (trainEquipmentScrollPanel) {
            var trainEquipmentItems = document.querySelectorAll(".train-equipment-item");
            var trainEquipmentContent = document.querySelector(".train-equipment-content");
            trainEquipmentScrollPanel.addEventListener("scroll", function (event) {
                event.preventDefault();
                event.stopPropagation();
                doTrainEquipmentPosition();
            });
            trainEquipmentScrollPanel.addEventListener("touchmove", function (event) {
                doTrainEquipmentPosition();
            });
            setTimeout(function () {
                doTrainEquipmentPosition();
            }, 500);
            var trainEquipmentForwardLink = document.querySelector(".train-equipment .forward-link");
            var trainEquipmentBackwardLink = document.querySelector(".train-equipment .backward-link");
            trainEquipmentForwardLink.addEventListener("mousedown", function (event) {
                event.preventDefault();
                event.stopPropagation();
                equipmentScrollRight = window.requestAnimationFrame(scrollToRight);
            });
            trainEquipmentForwardLink.addEventListener("mouseup", function (event) {
                event.preventDefault();
                event.stopPropagation();
                window.cancelAnimationFrame(equipmentScrollRight);
                window.cancelAnimationFrame(equipmentScrollLeft);
            });
            var keyDownForwardStarted_1 = false;
            trainEquipmentForwardLink.addEventListener("keydown", function (event) {
                if (event.keyCode !== 13 && event.keyCode !== 32)
                    return;
                event.preventDefault();
                event.stopPropagation();
                if (!keyDownForwardStarted_1) {
                    equipmentScrollRight = window.requestAnimationFrame(scrollToRight);
                    keyDownForwardStarted_1 = true;
                }
            });
            trainEquipmentForwardLink.addEventListener("keyup", function (event) {
                event.preventDefault();
                event.stopPropagation();
                window.cancelAnimationFrame(equipmentScrollRight);
                window.cancelAnimationFrame(equipmentScrollLeft);
                keyDownForwardStarted_1 = false;
            });
            trainEquipmentForwardLink.addEventListener("touchstart", function (event) {
                event.preventDefault();
                event.stopPropagation();
                scrollToRight();
            });
            trainEquipmentForwardLink.addEventListener("touchend", function (event) {
                event.preventDefault();
                event.stopPropagation();
                window.cancelAnimationFrame(equipmentScrollRight);
                window.cancelAnimationFrame(equipmentScrollLeft);
            });
            trainEquipmentForwardLink.addEventListener("click", function (event) {
                event.preventDefault();
                event.stopPropagation();
                // clearTimeout(equipmentScrollRight);
            });
            trainEquipmentBackwardLink.addEventListener("mousedown", function (event) {
                event.preventDefault();
                event.stopPropagation();
                equipmentScrollLeft = window.requestAnimationFrame(scrollToLeft);
            });
            trainEquipmentBackwardLink.addEventListener("mouseup", function (event) {
                event.preventDefault();
                event.stopPropagation();
                window.cancelAnimationFrame(equipmentScrollRight);
                window.cancelAnimationFrame(equipmentScrollLeft);
            });
            var keyDownBackwardStart_1 = false;
            trainEquipmentBackwardLink.addEventListener("keydown", function (event) {
                if (event.keyCode !== 13 && event.keyCode !== 32)
                    return;
                event.preventDefault();
                event.stopPropagation();
                if (!keyDownBackwardStart_1) {
                    equipmentScrollLeft = window.requestAnimationFrame(scrollToLeft);
                    keyDownBackwardStart_1 = true;
                }
            });
            trainEquipmentBackwardLink.addEventListener("keyup", function (event) {
                event.preventDefault();
                event.stopPropagation();
                window.cancelAnimationFrame(equipmentScrollRight);
                window.cancelAnimationFrame(equipmentScrollLeft);
                keyDownBackwardStart_1 = false;
            });
            trainEquipmentBackwardLink.addEventListener("touchstart", function (event) {
                event.preventDefault();
                event.stopPropagation();
                equipmentScrollLeft = window.requestAnimationFrame(scrollToLeft);
            });
            trainEquipmentBackwardLink.addEventListener("touchend", function (event) {
                event.preventDefault();
                event.stopPropagation();
                window.cancelAnimationFrame(equipmentScrollRight);
                window.cancelAnimationFrame(equipmentScrollLeft);
            });
            trainEquipmentBackwardLink.addEventListener("click", function (event) {
                event.preventDefault();
                event.stopPropagation();
                // clearTimeout(equipmentScrollRight);
            });
            if (trainEquipment.querySelector('.js-train-equipment-toggle')) {
                var radioButtons = trainEquipment.querySelectorAll('.js-train-equipment-toggle input[type="radio"]');
                var equipmentA_1 = trainEquipment.querySelectorAll('.js-train-equipment-a');
                var equipmentB_1 = trainEquipment.querySelectorAll('.js-train-equipment-b');
                var handleRadioChange_1 = function (event) {
                    var _a;
                    var selectedValue = (_a = event.target) === null || _a === void 0 ? void 0 : _a.value;
                    if (selectedValue == "equipmentA") {
                        toggleEquipment_1(equipmentA_1, equipmentB_1);
                    }
                    else {
                        toggleEquipment_1(equipmentB_1, equipmentA_1);
                    }
                };
                var toggleEquipment_1 = function (equipmentIn, equipmentOut) {
                    equipmentIn.forEach(function (el) {
                        el.style.display = 'block';
                    });
                    window.getComputedStyle(equipmentIn[0]).display; // Trigger layout after display block for animation
                    equipmentIn.forEach(function (el) {
                        el.hidden = false;
                    });
                    equipmentOut.forEach(function (el) {
                        el.hidden = true;
                    });
                    // Remove layout after animation
                    setTimeout(function () {
                        equipmentOut.forEach(function (el) {
                            el.style.display = 'none';
                        });
                    }, 1000);
                    trainEquipmentItems = document.querySelectorAll('.train-equipment-item');
                    doTrainEquipmentPosition();
                };
                toggleEquipment_1(equipmentA_1, equipmentB_1);
                radioButtons.forEach(function (radioButton) {
                    radioButton.addEventListener('change', handleRadioChange_1);
                });
            }
        }
    }
    return TrainEquipment;
}());
exports.TrainEquipment = TrainEquipment;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanoramaImage = void 0;
var I18n_1 = require("./I18n");
var utils_1 = require("./utils");
var PanoramaImage = /** @class */ (function () {
    function PanoramaImage(pannellum, element, lang) {
        this.lang = 'de';
        this.pannellum = pannellum;
        this.element = element;
        this.lang = lang;
        this.i18n = new I18n_1.I18n(this.lang);
        this.initDom();
    }
    PanoramaImage.prototype.initDom = function () {
        var panorama = this.element.getAttribute('data-panorama-image-url');
        if (window.innerWidth <= 640 && this.element.hasAttribute('data-panorama-image-url-smaller')) {
            panorama = this.element.getAttribute('data-panorama-image-url-smaller');
        }
        this.pannellum.viewer('panorama', {
            'type': 'equirectangular',
            'panorama': panorama,
            'strings': {
                'loadButtonLabel': this.i18n.getProperty('train-equipment.load', 'Panoramabild laden'),
                'loadingLabel': this.i18n.getProperty('train-equipment.loading', 'Lade ...')
            },
            'autoLoad': false,
            'compass': false,
        });
    };
    PanoramaImage.addStyleAndScript = function (onLoadCallback) {
        if (!document.querySelector('js-pannellum-style')) {
            var linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.href = (0, utils_1.getResourceUrl)('css/vendor/pannellum.css');
            linkElement.classList.add('js-pannellum-style');
            document.head.appendChild(linkElement);
        }
        if (!document.querySelector('js-pannellum-style')) {
            var styleElement = document.createElement('style');
            styleElement.appendChild(document.createTextNode("\n                #panorama {\n                    width: 100%;\n                    height: 400px;\n                }\n            "));
            document.head.appendChild(styleElement);
        }
        if (!document.querySelector('js-pannellum-script')) {
            var scriptElement = document.createElement('script');
            scriptElement.async = true;
            scriptElement.defer = true;
            scriptElement.src = (0, utils_1.getResourceUrl)('js/vendor/pannellum.js');
            scriptElement.classList.add('js-pannellum-script');
            scriptElement.addEventListener('load', function () {
                onLoadCallback();
            });
            document.body.appendChild(scriptElement);
        }
    };
    return PanoramaImage;
}());
exports.PanoramaImage = PanoramaImage;

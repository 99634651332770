"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanoramaCollection = void 0;
var PanoramaCollection = /** @class */ (function () {
    function PanoramaCollection(element) {
        this.element = element;
        this.initDom();
        this.initListeners();
    }
    PanoramaCollection.prototype.initDom = function () {
        this.sections = this.element.querySelectorAll('.js-panorama-collection-section');
        this.sectionTabs = this.element.querySelectorAll('.js-panorama-collection-section-tab');
        this.itemTabsWrappers = this.element.querySelectorAll('.js-panorama-collection-item-tabs');
        this.itemThumbnails = this.element.querySelectorAll('.js-panorama-collection-thumbnail');
        this.sections.forEach(function (section) { return section.hidden = true; });
        this.itemTabsWrappers.forEach(function (tabs) { return tabs.hidden = false; });
        this.lastSectionIndex = 0;
        this.lastSection = this.sections.item(0);
        this.showSection();
        this.showItem();
    };
    PanoramaCollection.prototype.initListeners = function () {
        var _this = this;
        this.sectionTabs.forEach(function (sectionTab, i) {
            sectionTab.addEventListener('click', function (e) {
                e.preventDefault();
                _this.showSection(i);
                _this.showItem();
            });
        });
        this.itemThumbnails.forEach(function (itemThumbnail, i) {
            itemThumbnail.addEventListener('click', function (e) {
                e.preventDefault();
                _this.showItem(i);
            });
        });
    };
    PanoramaCollection.prototype.showSection = function (index) {
        if (index === void 0) { index = 0; }
        this.sections.item(this.lastSectionIndex).hidden = true;
        this.sectionTabs.item(this.lastSectionIndex).classList.remove('panorama-collection-section-tab--active');
        this.sections.item(index).hidden = false;
        this.sectionTabs.item(index).classList.add('panorama-collection-section-tab--active');
        this.lastSectionIndex = index;
        this.lastSection = this.sections.item(index);
    };
    PanoramaCollection.prototype.showItem = function (index) {
        if (index === void 0) { index = 0; }
        var items = this.lastSection.querySelectorAll('.js-panorama-collection-item');
        items.forEach(function (item) { return item.hidden = true; });
        items.item(index).hidden = false;
        var thumbnails = this.lastSection.querySelectorAll('.js-panorama-collection-thumbnail');
        thumbnails.forEach(function (thumbnail) { return thumbnail.classList.remove('panorama-collection-thumbnail--active'); });
        thumbnails.item(index).classList.add('panorama-collection-thumbnail--active');
    };
    return PanoramaCollection;
}());
exports.PanoramaCollection = PanoramaCollection;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContrastSwitch = void 0;
var ContrastSwitch = /** @class */ (function () {
    function ContrastSwitch(element, cb) {
        this.isContrastActive = false;
        this.names = {
            storageProperty: 'contrast'
        };
        this.element = element;
        this.cb = cb;
        this.initDom();
        this.initListeners();
    }
    ContrastSwitch.prototype.initDom = function () {
        if (localStorage.getItem(this.names.storageProperty) && localStorage.getItem(this.names.storageProperty) === true.toString()) {
            this.toggleContrast();
        }
    };
    ContrastSwitch.prototype.initListeners = function () {
        var _this = this;
        this.element.addEventListener('click', function (e) {
            e.preventDefault();
            _this.toggleContrast();
            _this.updateContrastSetting();
        }, false);
    };
    ContrastSwitch.prototype.toggleContrast = function (blurAfterToggling) {
        if (blurAfterToggling === void 0) { blurAfterToggling = false; }
        this.isContrastActive = document.documentElement.classList.toggle('contrast');
        this.element.classList.toggle('active');
        if (blurAfterToggling)
            this.element.blur(); // Remove focus from switch
        localStorage.setItem(this.names.storageProperty, true.toString());
        if (this.cb)
            this.cb();
    };
    ContrastSwitch.prototype.updateContrastSetting = function () {
        localStorage.setItem(this.names.storageProperty, this.isContrastActive.toString());
    };
    return ContrastSwitch;
}());
exports.ContrastSwitch = ContrastSwitch;

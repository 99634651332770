"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeolocationService = exports.noMatchCountryCode = exports.availableCountriesCodes = void 0;
var resultFallback = {
    countryCode: 'at',
    countryName: [
        {
            language: 'de',
            name: 'Österreich',
        },
    ],
    isFallback: true,
};
exports.availableCountriesCodes = ['at', 'ch', 'de', 'it', 'be', 'nl', 'fr', 'hr', 'cz', 'sk', 'hu', 'si', 'pl', 'se', 'dk'];
exports.noMatchCountryCode = 'none';
var GeolocationService = /** @class */ (function () {
    function GeolocationService() {
        this.isLoading = false;
        this.callbackStack = [];
    }
    GeolocationService.getInstance = function () {
        if (!GeolocationService.instance) {
            GeolocationService.instance = new GeolocationService();
        }
        return GeolocationService.instance;
    };
    GeolocationService.prototype.fetch = function (cb) {
        var _this = this;
        this.addCallback(cb);
        if (this.result) {
            this.resolve();
            return;
        }
        if (this.isLoading)
            return;
        this.isLoading = true;
        fetch(GeolocationService.url)
            .then(function (response) {
            if (!response.ok)
                throw new Error('Error while contacting geolocation API.');
            return response.json();
        })
            .then(function (json) {
            _this.result = json;
            _this.isLoading = false;
            _this.resolve();
        })
            .catch(function (error) {
            _this.result = resultFallback;
            _this.isLoading = false;
            console.log("Error while loading geolocation, using fallback value [".concat(_this.result.countryCode, "]: ") + error);
            _this.resolve();
        });
    };
    GeolocationService.prototype.addCallback = function (cb) {
        this.callbackStack.push(cb);
    };
    GeolocationService.prototype.resolve = function () {
        for (var i = 0; i < this.callbackStack.length; i++) {
            this.callbackStack[i](this.result);
            this.callbackStack.splice(i, 1);
            i--;
        }
    };
    GeolocationService.url = 'https://pv-apps.web.oebb.at/geolocation';
    return GeolocationService;
}());
exports.GeolocationService = GeolocationService;

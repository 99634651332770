"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Him = void 0;
var Him = /** @class */ (function () {
    function Him(him) {
        var _this = this;
        this.items = [];
        this.him = him;
        // this.url = him.dataset.url; // Problem with IE10
        this.url = him.getAttribute('data-url');
        var xhr = new XMLHttpRequest();
        xhr.open('GET', "".concat(this.url, "?t=") + new Date().getTime(), true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                _this.items = JSON.parse(xhr.response).items;
                _this.renderHimMessages();
            }
        };
        xhr.send(null);
    }
    Him.prototype.renderHimMessages = function () {
        if (this.items.length == 0)
            return;
        this.him.querySelector('.js-him-summary').setAttribute('data-him-count', String(this.items.length));
        this.him.querySelector('.js-him-message-title').textContent = this.items[0].title;
        this.him.querySelector('.js-him-message-link').href = this.items[0].link;
        this.him.querySelector('.js-him-message-link').dataset.contentPiece = this.items[0].title;
        this.him.querySelector('.js-him-icon').classList.remove('ic-info-gray');
        this.him.querySelector('.js-him-icon').classList.add('ic-warning-orange');
        this.him.insertAdjacentHTML('beforeend', "\n            <div class=\"him__detail js-him-detail\">\n                <p class=\"js-him-detail-text\">".concat(this.items[0].description, "</p>\n            </div>\n        "));
    };
    return Him;
}());
exports.Him = Him;

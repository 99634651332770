"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryStringHandler = void 0;
var QueryStringHandler = /** @class */ (function () {
    function QueryStringHandler(links) {
        this.links = links;
    }
    QueryStringHandler.prototype.handle = function (name) {
        if (location.search) {
            var regex = new RegExp("".concat(name, "=([^&]*)"));
            var match = location.search.match(regex);
            if (!match || !match.length)
                return;
            var value = match[1];
            if (value) {
                document.cookie = "".concat(name, "=").concat(value, ";path=/"); // No "expire" -> Session cookie
                this.append(name, value);
                return value;
            }
        }
        else if (document.cookie.indexOf(name) > -1) {
            var regex = new RegExp("".concat(name, "=([^;]*)"));
            var match = document.cookie.match(regex);
            if (!match || !match.length)
                return;
            var value = match[1];
            this.append(name, value);
            return value;
        }
    };
    QueryStringHandler.prototype.append = function (name, value) {
        for (var i = 0; i < this.links.length; i++) {
            var regex = new RegExp("".concat(name, "=([^&]*)"));
            var match = this.links[i].href.match(regex);
            if (match) {
                // Parameter name exists on link, replace
                this.links[i].href = this.links[i].href.replace(match[0], "".concat(name, "=").concat(value));
            }
            else if (this.links[i].href.match(/\?+/)) {
                // Parameter name does not exists on link, but link has query strings
                this.links[i].href = this.links[i].href + "&".concat(name, "=").concat(value);
            }
            else {
                // Parameter name does not exist on link and non query strings append
                this.links[i].href = this.links[i].href + "?".concat(name, "=").concat(value);
            }
        }
    };
    return QueryStringHandler;
}());
exports.QueryStringHandler = QueryStringHandler;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeaserSlider = void 0;
var TeaserType;
(function (TeaserType) {
    TeaserType["One"] = "teaser-slider-one";
    TeaserType["Two"] = "teaser-slider-two";
    TeaserType["Three"] = "teaser-slider-three";
    TeaserType["Four"] = "teaser-slider-four";
    TeaserType["Five"] = "teaser-slider-five";
    TeaserType["Six"] = "teaser-slider-six";
    TeaserType["Quote"] = "quotes-slider";
    TeaserType["Gallery"] = "gallery-slider";
    TeaserType["NoClick"] = "teaser-slider-noclick";
    TeaserType["Overlay"] = "teaser-slider-overlay";
})(TeaserType || (TeaserType = {}));
var TeaserSlider = /** @class */ (function () {
    function TeaserSlider(element) {
        this.swipeDistance = 50;
        this.swipeEnabled = false;
        this.swipeActionDone = false;
        this.eventsBound = false;
        this.currentFirst = 0;
        this.isDialogOpen = false;
        var self = this;
        this.element = element;
        this.panel = this.element.querySelector(".teaser-slider-panel");
        this.wrapper = this.element.querySelector(".teaser-slider-list-wrapper");
        this.list = this.element.querySelector(".teaser-slider-list");
        this.closeLink = this.element.querySelector(".close-link");
        this.backwardLink = this.element.querySelector(".backward-link");
        this.forwardLink = this.element.querySelector(".forward-link");
        this.teaserItems = this.list.querySelectorAll("li");
        this.toggleNavigationVisibility();
        this.marginLeft = 0;
        var imgs = this.list.getElementsByTagName('img');
        for (var i = 0; i < imgs.length; i++) {
            imgs[i].onmousedown = function (e) { return e.preventDefault(); };
            imgs[i].setAttribute("data-index", i + "");
            imgs[i].parentElement.setAttribute("data-index", i + "");
            if (imgs[i].getAttribute("data-title")) {
                var titleSpan = document.createElement("span");
                titleSpan.classList.add("img-title");
                titleSpan.innerText = imgs[i].getAttribute("data-title");
                imgs[i].parentElement.appendChild(titleSpan);
            }
            if (imgs[i].getAttribute("data-copyright")) {
                var copyrightSpan = document.createElement("span");
                copyrightSpan.classList.add("img-copyright");
                copyrightSpan.innerText = imgs[i].getAttribute("data-copyright");
                imgs[i].parentElement.appendChild(copyrightSpan);
            }
        }
        self.init(null);
        this.initEventListeners();
    }
    TeaserSlider.prototype.getElement = function () {
        return this.element;
    };
    TeaserSlider.prototype.getListElement = function () {
        return this.list;
    };
    TeaserSlider.prototype.doBackward = function () {
        this.swipeActionDone = true;
        this.list.style.transitionProperty = "transform";
        this.list.style.transitionDuration = "0.4s";
        this.marginLeft = this.marginLeft - ((this.itemWidth + 21) * -1);
        if (this.marginLeft < 0) {
            this.activateTransition();
        }
        else {
            this.marginLeft = 0;
            this.activateTransition();
            this.hideBackwardLink();
            if (this.isDialogOpen)
                this.focusNextElement(this.backwardLink);
        }
        if (this.teaserPanelWidth <= this.listWidth + this.marginLeft && this.teaserItems.length > 1) {
            this.showForwardLink();
        }
    };
    TeaserSlider.prototype.doMove = function (distance) {
        this.swipeActionDone = true;
        this.activateTransition(this.marginLeft - distance);
    };
    TeaserSlider.prototype.doForward = function () {
        this.swipeActionDone = true;
        if (this.isForwardVisible()) {
            this.marginLeft = this.marginLeft + ((this.itemWidth + 21) * -1);
            this.activateTransition();
            this.showBackwardLink();
            if (this.teaserPanelWidth >= this.listWidth + this.marginLeft - 5) {
                this.hideForwardLink();
                if (this.isDialogOpen)
                    this.focusNextElement(this.forwardLink);
            }
        }
    };
    TeaserSlider.prototype.init = function (showItem) {
        var _this = this;
        var self = this;
        setTimeout(function () {
            self.teaserPanelWidth = self.panel.clientWidth;
            var padding = "";
            if (self.element.classList.contains(TeaserType.One)) {
                self.itemWidth = Math.round((self.teaserPanelWidth));
                if (self.panel.clientWidth / 3 * 2 > self.panel.clientHeight) {
                    if (!_this.teaserTypeEquals(TeaserType.Quote)) {
                        padding = (self.panel.clientWidth / 3 * 2 - self.panel.clientHeight) + "px";
                    }
                }
            }
            else if (self.element.classList.contains(TeaserType.Two)) {
                self.itemWidth = Math.round((self.teaserPanelWidth - 21) / 2);
                if (self.itemWidth < 320) {
                    self.itemWidth = 320;
                }
            }
            else if (_this.teaserTypeEquals(TeaserType.Six)) {
                self.itemWidth = Math.round((self.teaserPanelWidth - 21) / 6);
                if (self.itemWidth < 180) {
                    self.itemWidth = 180;
                }
            }
            else {
                self.itemWidth = Math.round((self.teaserPanelWidth - 42) / 3);
                if (self.itemWidth < 90) {
                    self.itemWidth = 280; // Fit on screens <= 320px width
                }
                else if (self.itemWidth < 300) {
                    self.itemWidth = 300;
                }
            }
            for (var i = 0; i < self.teaserItems.length; i++) {
                var currentElement = self.teaserItems[i];
                currentElement.style.width = self.itemWidth + "px";
                currentElement.style.paddingLeft = padding;
                currentElement.style.paddingRight = padding;
            }
            self.listWidth = self.list.scrollWidth;
            if (self.teaserPanelWidth < self.listWidth && _this.teaserItems.length > 1) {
                _this.showForwardLink();
            }
            else {
                _this.hideForwardLink();
            }
            if (self.element.classList.contains("gallery-slider")) {
                if (!self.eventsBound) {
                    if (self.closeLink) {
                        self.closeLink.addEventListener("click", function (e) {
                            self.preventDefaults(e);
                            _this.closeDialog();
                        });
                        self.closeLink.addEventListener('keydown', function (e) {
                            if (!_this.isDialogOpen)
                                return;
                            self.preventDefaults(e);
                            if (e.key == "Space") {
                                return _this.closeDialog();
                            }
                            ;
                            if (e.shiftKey && e.key == "Tab") {
                                _this.focusPreviousElement(e.currentTarget);
                            }
                            else if (e.key == "Tab") {
                                _this.focusNextElement(e.currentTarget);
                            }
                            return;
                        });
                        self.backwardLink.addEventListener('keydown', function (e) {
                            if (!_this.isDialogOpen)
                                return;
                            e.preventDefault();
                            if (e.shiftKey && e.key == "Tab") {
                                _this.focusPreviousElement(e.currentTarget);
                            }
                            else if (e.key == "Tab") {
                                _this.focusNextElement(e.currentTarget);
                            }
                            return;
                        });
                        self.forwardLink.addEventListener('keydown', function (e) {
                            if (!_this.isDialogOpen)
                                return;
                            e.preventDefault();
                            if (e.shiftKey && e.key == "Tab") {
                                _this.focusPreviousElement(e.currentTarget);
                            }
                            else if (e.key == "Tab") {
                                _this.focusNextElement(e.currentTarget);
                            }
                            return;
                        });
                        document.addEventListener("keyup", function (event) {
                            if (event.key === "Escape") {
                                _this.closeDialog();
                            }
                        });
                    }
                    self.eventsBound = true;
                }
                if (_this.teaserTypeEquals(TeaserType.One)) {
                    self.wrapper.style.marginTop = (self.wrapper.offsetHeight / 2 * -1) + "px";
                    if (showItem && showItem > 0) {
                        self.marginLeft = self.marginLeft + ((self.itemWidth + 21) * -1) * showItem;
                        self.list.style.transitionProperty = "";
                        self.list.style.transitionDuration = "";
                        self.list.style.transform = "translate(" + self.marginLeft + "px, 0)";
                        if (self.teaserPanelWidth >= self.listWidth + self.marginLeft - 5) {
                            self.hideForwardLink();
                        }
                        else {
                            self.showForwardLink();
                        }
                        if (self.marginLeft == 0) {
                            self.hideBackwardLink();
                        }
                        else {
                            self.showBackwardLink();
                        }
                    }
                }
            }
        }, 200);
    };
    TeaserSlider.prototype.openDialog = function (dataIndex) {
        if (dataIndex === void 0) { dataIndex = 0; }
        if (!this.teaserTypeEquals(TeaserType.One)) {
            var imgs = this.list.getElementsByTagName('img');
            for (var i = 0; i < imgs.length; i++) {
                var srcBig = imgs[i].getAttribute("data-big-src");
                if (srcBig) {
                    imgs[i].setAttribute("data-small-src", imgs[i].src);
                    imgs[i].src = srcBig;
                }
                var srcsetBig = imgs[i].getAttribute("data-big-srcset");
                if (srcsetBig) {
                    imgs[i].setAttribute("data-small-srcset", imgs[i].srcset);
                    imgs[i].srcset = srcsetBig;
                }
            }
            this.marginLeft = 0;
            this.list.style.transform = "translate(0, 0)";
            this.element.classList.add(TeaserType.One);
            this.element.classList.add(TeaserType.Overlay);
            if (this.element.querySelector('h1, h2, h3, h4, h5, h6')) {
                this.element.querySelector('h1, h2, h3, h4, h5, h6').id = 'slider-overlay-label';
                this.element.setAttribute('aria-labelledby', 'slider-overlay-label');
            }
            this.element.setAttribute('role', 'dialog');
            this.element.setAttribute('aria-modal', 'true');
            this.lastDocumentElement = document.activeElement;
            this.closeLink.focus();
            this.isDialogOpen = true;
            this.addTabIndexToLinks(-1);
            this.init(dataIndex);
        }
    };
    TeaserSlider.prototype.closeDialog = function () {
        if (this.teaserTypeEquals(TeaserType.One)) {
            this.element.classList.remove(TeaserType.One);
            this.element.classList.remove(TeaserType.Overlay);
            var imgs = this.list.getElementsByTagName('img');
            for (var i = 0; i < imgs.length; i++) {
                var srcSmall = imgs[i].getAttribute("data-small-src");
                if (srcSmall) {
                    imgs[i].src = srcSmall;
                }
                var srcsetSmall = imgs[i].getAttribute("data-small-srcset");
                if (srcsetSmall) {
                    imgs[i].srcset = srcsetSmall;
                }
            }
            this.isDialogOpen = false;
            this.addTabIndexToLinks(0);
            this.focusLastDocumentElement();
            if (this.element.querySelector('h1, h2, h3, h4, h5, h6')) {
                this.element.querySelector('h1, h2, h3, h4, h5, h6').removeAttribute('id');
                this.element.removeAttribute('aria-labelledby');
            }
            this.element.removeAttribute('role');
            this.element.removeAttribute('aria-modal');
            this.list.style.transform = "translate(0, 0)";
            this.hideBackwardLink();
            this.marginLeft = 0;
            this.wrapper.style.marginTop = "0";
            this.init(null);
        }
    };
    TeaserSlider.prototype.focusLastDocumentElement = function () {
        if (this.lastDocumentElement)
            this.lastDocumentElement.focus();
    };
    TeaserSlider.prototype.getFocusableElements = function () {
        return this.element.querySelectorAll('.close-link, .backward-link, .forward-link');
    };
    TeaserSlider.prototype.focusNextElement = function (currentElement) {
        var elements = this.getFocusableElements();
        var currentElementPassed = false;
        for (var i = 0; i < elements.length; i++) {
            if (elements[i] == currentElement) {
                currentElementPassed = true;
                continue;
            }
            if (!currentElementPassed)
                continue;
            if (elements[i].style.display != 'none') {
                return elements[i].focus();
            }
        }
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].style.display != 'none') {
                return elements[i].focus();
            }
        }
    };
    TeaserSlider.prototype.focusPreviousElement = function (currentElement) {
        var elements = this.getFocusableElements();
        var currentElementPassed = false;
        for (var i = elements.length - 1; i >= 0; i--) {
            if (elements[i] == currentElement) {
                currentElementPassed = true;
                continue;
            }
            if (!currentElementPassed)
                continue;
            if (elements[i].style.display != 'none') {
                return elements[i].focus();
            }
        }
        for (var i = elements.length - 1; i >= 0; i--) {
            if (elements[i].style.display != 'none') {
                return elements[i].focus();
            }
        }
    };
    TeaserSlider.prototype.addTabIndexToLinks = function (tabIndex) {
        if (tabIndex === void 0) { tabIndex = 0; }
        for (var i = 0; i < this.teaserItems.length; i++) {
            this.teaserItems[i].querySelector('a').tabIndex = tabIndex;
        }
    };
    TeaserSlider.prototype.preventDefaults = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
    };
    TeaserSlider.prototype.initEventListeners = function () {
        var _this = this;
        var self = this;
        var touchStartX = 0;
        var touchEndX = 0;
        window.addEventListener('resize', function (evt) {
            self.marginLeft = 0;
            self.list.style.marginLeft = self.marginLeft + "";
            self.hideBackwardLink();
            self.listWidth = self.list.scrollWidth;
            self.init(self.currentFirst);
        });
        if (this.teaserItems.length > 1) {
            this.list.addEventListener("touchstart", function (evt) {
                touchStartX = evt.changedTouches.item(0).clientX;
            });
            this.list.addEventListener("touchmove", function (evt) {
                self.preventDefaults(evt);
                var touchMoveX = evt.changedTouches.item(0).clientX;
                if ((touchStartX > touchMoveX + 5) || (touchStartX < touchMoveX - 5)) {
                    self.doMove(touchStartX - touchMoveX);
                }
            });
            this.list.addEventListener("touchend", function (evt) {
                touchEndX = evt.changedTouches.item(0).clientX;
                if (self.isForwardVisible() && touchStartX - self.swipeDistance > touchEndX) {
                    self.preventDefaults(evt);
                    self.doForward();
                }
                else if (touchStartX + self.swipeDistance < touchEndX) {
                    self.preventDefaults(evt);
                    self.doBackward();
                }
                else {
                    self.activateTransition();
                }
            });
            this.list.addEventListener("mousedown", function (evt) {
                if (evt.button == 0) {
                    touchStartX = evt.clientX;
                    self.swipeEnabled = true;
                }
            });
            this.list.addEventListener("mousemove", function (evt) {
                self.preventDefaults(evt);
                if (self.swipeEnabled) {
                    self.doMove(touchStartX - evt.clientX);
                }
            });
            this.list.addEventListener("mouseup", function (evt) {
                self.swipeActionDone = false;
                touchEndX = evt.clientX;
                if (self.swipeEnabled) {
                    if (self.isForwardVisible() && touchStartX - self.swipeDistance > touchEndX) {
                        self.preventDefaults(evt);
                        self.doForward();
                    }
                    else if (touchStartX + self.swipeDistance < touchEndX) {
                        self.preventDefaults(evt);
                        self.doBackward();
                    }
                    else if (touchStartX != touchEndX) {
                        self.preventDefaults(evt);
                        self.list.style.transitionProperty = "transform";
                        self.list.style.transitionDuration = "0.4s";
                        self.list.style.transform = "translate(" + self.marginLeft + "px, 0)";
                        self.swipeActionDone = true;
                    }
                }
            });
        }
        this.list.addEventListener("click", function (evt) {
            if (self.swipeActionDone) {
                self.preventDefaults(evt);
            }
            else if (self.teaserTypeEquals(TeaserType.Gallery)) {
                self.preventDefaults(evt);
                if (!self.teaserTypeEquals(TeaserType.NoClick)) {
                    var currentTarget = evt.target;
                    var currentTargetIndex = parseInt(currentTarget.getAttribute("data-index"));
                    self.openDialog(currentTargetIndex);
                }
            }
            self.swipeEnabled = false;
            self.swipeActionDone = false;
        });
        this.list.addEventListener('keydown', function (e) {
            if (e.key == "Space") {
                e.preventDefault();
                var currentTargetIndex = parseInt(e.target.dataset.index);
                _this.openDialog(currentTargetIndex);
            }
        });
        this.list.addEventListener("transitionend", function (evt) {
            self.deactivateTransition();
        });
        this.backwardLink.addEventListener('click', function (evt) {
            self.preventDefaults(evt);
            self.doBackward();
        });
        this.forwardLink.addEventListener('click', function (evt) {
            self.preventDefaults(evt);
            self.doForward();
        });
        document.addEventListener("keyup", function (event) {
            self.preventDefaults(event);
            if (self.teaserTypeEquals(TeaserType.One)) {
                switch (event.key) {
                    case "ArrowRight":
                        self.doForward();
                        break;
                    case "ArrowLeft":
                        self.doBackward();
                        break;
                }
            }
        });
    };
    TeaserSlider.prototype.deactivateTransition = function () {
        this.list.style.transitionProperty = "";
        this.list.style.transitionDuration = "";
    };
    TeaserSlider.prototype.activateTransition = function (x) {
        if (x == undefined || x == null) {
            x = this.marginLeft;
        }
        this.list.style.transitionProperty = "transform";
        this.list.style.transitionDuration = "0.4s";
        this.list.style.transform = "translate(" + x + "px, 0)";
    };
    TeaserSlider.prototype.isForwardVisible = function () {
        return this.forwardLink.style.display == "block";
    };
    TeaserSlider.prototype.hideForwardLink = function () {
        this.forwardLink.style.display = "none";
    };
    TeaserSlider.prototype.showForwardLink = function () {
        this.forwardLink.style.display = "block";
    };
    TeaserSlider.prototype.hideBackwardLink = function () {
        this.backwardLink.style.display = "none";
    };
    TeaserSlider.prototype.showBackwardLink = function () {
        this.backwardLink.style.display = "block";
    };
    TeaserSlider.prototype.toggleNavigationVisibility = function () {
        if (this.teaserItems.length > 1) {
            this.showForwardLink();
        }
        else {
            this.hideForwardLink();
        }
        this.hideBackwardLink();
    };
    TeaserSlider.prototype.teaserTypeEquals = function (type) {
        return this.element.classList.contains(type);
    };
    return TeaserSlider;
}());
exports.TeaserSlider = TeaserSlider;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PiwikTracking = void 0;
var PiwikTracking = /** @class */ (function () {
    function PiwikTracking() {
        if (window._paq) {
            this.trackErrorPages();
            this.trackRedirectDetails();
            this.trackYouTubeOverlay();
        }
    }
    PiwikTracking.prototype.trackErrorPages = function () {
        var errorPage = document.querySelector('.js-error-page');
        if (errorPage) {
            if (document.location.href.indexOf("fehlerseiten") < 1) {
                var backlink = document.referrer.length > 0 ? document.referrer : "";
            }
            else {
                var backlink = document.location.href;
            }
            window._paq.push(['trackEvent', 'Error', errorPage.getAttribute('data-title'), backlink]);
        }
    };
    PiwikTracking.prototype.trackRedirectDetails = function () {
        var redirectDetails = document.querySelector('.js-redirect-details');
        if (redirectDetails) {
            window._paq.push([
                'trackEvent',
                'Error',
                redirectDetails.getAttribute('data-title'),
                document.location.href
            ]);
        }
    };
    PiwikTracking.prototype.trackYouTubeOverlay = function () {
        var _a, _b;
        if (!document.querySelector('.overlay--youtube'))
            return;
        var overlays = document.querySelectorAll('.overlay--youtube');
        var pageTitle = (_a = document.title) !== null && _a !== void 0 ? _a : 'Kein Seitentitel';
        var _loop_1 = function (i) {
            var overlayTitle = (_b = overlays[i].getAttribute('data-overlay-title')) !== null && _b !== void 0 ? _b : 'Kein Overlay Titel';
            overlays[i].addEventListener('click', function () {
                window._paq.push(['trackEvent', 'Video', 'YouTube Overlay', pageTitle + ' - ' + overlayTitle]);
            });
        };
        for (var i = 0; i < overlays.length; i++) {
            _loop_1(i);
        }
    };
    return PiwikTracking;
}());
exports.PiwikTracking = PiwikTracking;
